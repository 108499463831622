import Card1 from "./Card1";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const CardGridIcon = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <section className={`card-grid-icon ${className}`}>
      <div className="frame-parent">
        <br />
        <br />
        <br />
        <h1 className="how-it-works">How it works</h1>
        <br />
      </div>
      <div className="cards">
        <Card1
          link2="/help-circle.svg"
          heading="Create an Ask"
          body="Easily post a request for anything you need help with, from borrowing an item to seeking assistance for a quick task."
          star="/star.svg"
          x="/"
          asset
          button={false}
          propFlex="1"
          star1="/star.svg"
          label="Button"
          x1="/"
          hasIconStart={false}
          hasIconEnd={false}
          propHeight="40px"
          propBorderRadius="var(--radius-200)"
          propBackgroundColor="#e3e3e3"
          propBorder="1px solid #767676"
          propAlignSelf="stretch"
          propColor="#1e1e1e"
          propDisplay="unset"
          propMinWidth="unset"
          propFontWeight="unset"
        />
        <Card1
          link2="/message-circle.svg"
          heading="Get a Response"
          body="Neighbors receive instant notifications of your request and can respond quickly, ensuring timely help."
          star="/star.svg"
          x="/"
          asset
          button={false}
          propFlex="1"
          propWidth="unset"
          star1="/star.svg"
          label="Button"
          x1="/"
          hasIconStart={false}
          hasIconEnd={false}
          propHeight="40px"
          propBorderRadius="var(--radius-200)"
          propBackgroundColor="#e3e3e3"
          propBorder="1px solid #767676"
          propAlignSelf="stretch"
          propColor="#1e1e1e"
          propDisplay="unset"
          propMinWidth="unset"
          propFontWeight="unset"
        />
        <Card1
          link2="/link-2.svg"
          heading="Build Connections"
          body="As you engage with the platform, build lasting relationships with your neighbors, enhancing community trust and support."
          star="/star.svg"
          x="/"
          asset
          star1="/star.svg"
          label="Button"
          x1="/"
          hasIconStart={false}
          hasIconEnd={false}
          propHeight="40px"
          propBorderRadius="var(--radius-200)"
          propBackgroundColor="#e3e3e3"
          propBorder="1px solid #767676"
          propAlignSelf="stretch"
          propColor="#1e1e1e"
          propDisplay="unset"
          propMinWidth="unset"
          propFontWeight="unset"
        />
      </div>
      <div className="get-connected">
        <button
          className="get-connected-for-free-wrapper"
          onClick={() => {
            navigate("/home");
          }}
        >
          <i className="get-connected-for">Get connected for free</i>
        </button>
      </div>
      <br />
    </section>
  );
};

CardGridIcon.propTypes = {
  className: PropTypes.string,
};

export default CardGridIcon;
