import { Box } from "@mui/material";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import React from "react";

const TermsConditions = () => {
  return (
    <Box
      sx={{
        padding: "20px",
      }}
      className="terms-and-conditions"
    >
      <Layout />

      <h1>Terms and Conditions</h1>

      <p>
        Welcome to CuppaSugar. By accessing or using our platform, you agree to
        be bound by these Terms and Conditions ("Terms"). Please read these
        Terms carefully before using our services. If you do not agree to these
        Terms, you may not use CuppaSugar.
      </p>

      <h2>1. Introduction</h2>
      <p>
        CuppaSugar provides a platform that connects neighbors to borrow, lend,
        and offer assistance through requests and responses. These Terms govern
        the use of CuppaSugar and all related services provided through our
        platform.
      </p>

      <h2>2. Definitions</h2>
      <ul>
        <li>
          <strong>CuppaSugar:</strong> Refers to the web-based application and
          services provided by CuppaSugar, including all features and
          functionalities.
        </li>
        <li>
          <strong>User:</strong> Any individual who registers for and interacts
          with the CuppaSugar platform.
        </li>
        <li>
          <strong>Request:</strong> Any request made by a User seeking
          assistance or borrowing items from other Users.
        </li>
        <li>
          <strong>Response:</strong> Any response by a User offering assistance
          or lending items to another User.
        </li>
        <li>
          <strong>10DLC:</strong> Refers to the 10-digit long code used for SMS
          messaging campaigns, ensuring compliance with industry standards.
        </li>
      </ul>

      <h2>3. Eligibility</h2>
      <p>
        To use CuppaSugar, you must be at least 18 years old and capable of
        entering into legally binding agreements. By using our platform, you
        represent and warrant that you meet these eligibility requirements.
      </p>

      <h2>4. Account Registration</h2>
      <h3>4.1 Accurate Information</h3>
      <p>
        Users agree to provide accurate and complete information during the
        registration process and while making requests or responses. Users must
        promptly update their information if there are any changes.
      </p>

      <h3>4.2 Account Security</h3>
      <p>
        Users are responsible for maintaining the confidentiality of their
        account credentials and for all activities that occur under their
        account. CuppaSugar will not be liable for any loss or damage resulting
        from unauthorized use of your account.
      </p>

      <h2>5. User Responsibilities</h2>
      <h3>5.1 Conduct</h3>
      <p>
        Users agree to use CuppaSugar in a manner consistent with all applicable
        laws and regulations. Users must not use the platform for any unlawful
        or harmful activities, including but not limited to:
      </p>
      <ul>
        <li>Harassment, abuse, or harm towards other Users.</li>
        <li>Fraudulent activities or misrepresentation.</li>
        <li>
          Sharing or distributing inappropriate, offensive, or illegal content.
        </li>
      </ul>

      <h3>5.2 Responsibility for Interactions</h3>
      <p>
        Users acknowledge and accept full responsibility for their interactions
        with other Users. CuppaSugar does not conduct background checks or
        verify the identity of Users. Users are responsible for conducting their
        own assessments and taking necessary precautions when interacting with
        others.
      </p>

      <h3>5.3 SMS Notifications</h3>
      <p>
        By registering for CuppaSugar, Users consent to receiving SMS
        notifications related to requests, responses, and other platform
        activities. Message frequency varies. Message and data rates may apply.
        Reply HELP for help. Reply STOP to opt out. To comply with 10DLC
        requirements, Users must ensure their phone number is accurate and
        valid.
      </p>

      <h2>6. Requests and Responses</h2>
      <h3>6.1 Making a Request</h3>
      <p>
        Users can submit requests for borrowing items or asking for assistance
        through the platform. Requests should be clear, concise, and lawful.
        CuppaSugar reserves the right to remove or modify any request that
        violates these Terms.
      </p>

      <h3>6.2 Responding to a Request</h3>
      <p>
        Users can respond to requests made by other Users. Responses should be
        prompt and respectful. Users agree to fulfill their commitments made
        through the platform.
      </p>

      <h3>6.3 Limitation of Liability</h3>
      <p>
        CuppaSugar is not responsible for the actions, quality, or outcomes of
        any interactions between Users. Users agree to release CuppaSugar from
        any claims or liabilities arising from their use of the platform.
      </p>

      <h2>7. SMS Messaging Compliance</h2>
      <h3>7.1 10DLC Compliance</h3>
      <p>
        CuppaSugar uses 10DLC for SMS messaging, which ensures compliance with
        industry standards for messaging campaigns. Users acknowledge that:
      </p>
      <ul>
        <li>Messages may be subject to filtering by carriers.</li>
        <li>
          Certain types of content may be restricted or prohibited by 10DLC
          regulations.
        </li>
        <li>
          Users must comply with all applicable messaging laws and guidelines.
        </li>
        <li>Message frequency varies.</li>
        <li>Message and data rates may apply.</li>
        <li>Users can reply HELP for help.</li>
        <li>Users can reply STOP to opt out.</li>
      </ul>

      <h3>7.2 Opting Out</h3>
      <p>
        Users can opt out of receiving SMS notifications at any time by replying
        STOP to the messages. Opting out of SMS notifications may limit the
        functionality and usability of the platform.
      </p>

      <h2>8. Intellectual Property</h2>
      <h3>8.1 Ownership</h3>
      <p>
        CuppaSugar retains all rights, titles, and interests in and to the
        platform, including all intellectual property rights. Users are granted
        a limited, non-exclusive, non-transferable license to use the platform
        for personal, non-commercial purposes.
      </p>

      <h3>8.2 User Content</h3>
      <p>
        Users retain ownership of any content they submit to the platform. By
        submitting content, Users grant CuppaSugar a worldwide, royalty-free,
        perpetual license to use, modify, distribute, and display the content
        for the purpose of operating and improving the platform.
      </p>

      <h2>9. Privacy and Data Security</h2>
      <p>
        CuppaSugar is committed to protecting your privacy. For details on how
        we collect, use, and protect your data, please refer to our{" "}
        <Link to={"/privacy"} target="_blank">
          Privacy Policy
        </Link>
        .
      </p>

      <h2>10. Termination</h2>
      <p>
        CuppaSugar reserves the right to terminate or suspend a User's account
        at our discretion, without notice, if we believe the User has violated
        these Terms or engaged in harmful activities.
      </p>

      <h2>11. Changes to Terms and Conditions</h2>
      <p>
        CuppaSugar may update these Terms from time to time. We will notify
        Users of any significant changes by posting the updated Terms on our
        platform. Continued use of the platform after such changes constitutes
        acceptance of the updated Terms.
      </p>

      <h2>12. Limitation of Liability</h2>
      <h3>12.1 General Limitation</h3>
      <p>
        CuppaSugar is provided on an "as is" and "as available" basis. We make
        no warranties, express or implied, regarding the platform's performance,
        availability, or suitability for any purpose. We do not guarantee that
        the platform will be free from errors, interruptions, or security
        breaches.
      </p>

      <h3>12.2 No Liability for User Actions</h3>
      <p>
        CuppaSugar is not liable for any actions, disputes, or damages resulting
        from interactions between Users. Users agree to release CuppaSugar from
        any claims or liabilities arising from their use of the platform.
      </p>

      <h2>13. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of the jurisdiction in which CuppaSugar operates, without regard to
        its conflict of law principles.
      </p>

      <h2>14. Liability Waiver</h2>
      <h3>14.1 Interactions with Neighbors</h3>
      <p>
        CuppaSugar provides a platform for neighbors to connect, make requests,
        and offer assistance or items. However, we do not monitor or control the
        behavior of users outside of the platform. CuppaSugar is not responsible
        for any actions, agreements, disputes, or outcomes that arise from
        interactions between users, whether online or offline.
      </p>

      <h3>14.2 No Background Checks</h3>
      <p>
        CuppaSugar does not conduct background checks, verify the identity, or
        vet users before allowing them to join or interact on the platform. It
        is your responsibility to take appropriate precautions and exercise
        common sense when interacting with others.
      </p>

      <h3>14.3 Assumption of Risk</h3>
      <p>
        By using the CuppaSugar platform, you agree to voluntarily assume all
        risks associated with interactions or transactions made through the
        platform.
      </p>

      <h3>14.4 No Guarantees or Warranties</h3>
      <p>
        CuppaSugar does not guarantee the quality, safety, suitability, or
        legality of any requests, responses, or items exchanged between users.
      </p>

      <h3>14.5 Limitation of Liability</h3>
      <p>
        CuppaSugar, its officers, directors, employees, agents, licensors, and
        affiliates shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, including but not limited to:
      </p>
      <ul>
        <li>Loss of profits, data, or goodwill.</li>
        <li>
          Personal injury or property damage resulting from the use of the
          platform or any interactions between users.
        </li>
        <li>Any disputes or legal claims between users.</li>
      </ul>
      <p>
        In no event shall CuppaSugar's total liability to you exceed the amount
        you have paid (if any) to use our platform.
      </p>

      <h3>14.6 Release</h3>
      <p>
        By agreeing to these Terms, you hereby release and forever discharge
        CuppaSugar and its officers, directors, employees, agents, licensors,
        and affiliates from any and all claims, demands, causes of action,
        damages, liabilities, costs, and expenses (including attorneys' fees)
        arising out of or in connection with:
      </p>
      <ul>
        <li>
          Your use of the platform or any interactions between you and other
          users.
        </li>
        <li>
          Any claims or disputes with third parties related to your use of
          CuppaSugar or your interactions with other users.
        </li>
      </ul>

      <h3>14.7 Indemnification</h3>
      <p>
        You agree to indemnify and hold harmless CuppaSugar, its officers,
        directors, employees, agents, licensors, and affiliates from any claims,
        liabilities, damages, losses, or expenses (including reasonable legal
        fees) arising out of or in connection with:
      </p>
      <ul>
        <li>Your use of the platform.</li>
        <li>
          Any interaction, dispute, or agreement between you and other users.
        </li>
        <li>
          Your violation of these Terms or any applicable laws or regulations.
        </li>
      </ul>

      <h2>15. Dispute Resolution</h2>
      <p>
        Any disputes arising from or relating to the use of the platform will be
        subject to binding arbitration, in accordance with the laws of the
        jurisdiction in which CuppaSugar operates. Both parties agree to resolve
        disputes through arbitration rather than court proceedings.
      </p>

      <h2>16. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms or the platform,
        please contact us at:{" "}
        <a href="mailto:support@cuppasugar.io">support@cuppasugar.io</a>.
        CuppaSugar (KHAMLICHI LLC), 1501 Lincoln Blvd. #1053, Venice, CA 90291.
      </p>

      <p>
        By using CuppaSugar, you acknowledge that you have read, understood, and
        agree to be bound by these Terms and Conditions.
      </p>
    </Box>
  );
};

export default TermsConditions;
