import { useMemo } from "react";
import PropTypes from "prop-types";

const Button = ({
  className = "",
  star,
  label = "Button",
  x,
  hasIconStart = false,
  hasIconEnd = false,
  propHeight,
  propBorderRadius,
  propBackgroundColor,
  propBorder,
  propAlignSelf,
  propColor,
  propDisplay,
  propMinWidth,
  propFontWeight,
  onClick,
}) => {
  const buttonStyle = useMemo(() => {
    return {
      height: propHeight,
      borderRadius: propBorderRadius,
      backgroundColor: propBackgroundColor,
      border: propBorder,
    };
  }, [propHeight, propBorderRadius, propBackgroundColor, propBorder]);

  const button1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      color: propColor,
      display: propDisplay,
      minWidth: propMinWidth,
      fontWeight: propFontWeight,
    };
  }, [propAlignSelf, propColor, propDisplay, propMinWidth, propFontWeight]);

  return (
    <div
      className={`button20 ${className}`}
      style={buttonStyle}
      onClick={onClick}
    >
      {hasIconStart && <img className="star-icon7" alt="" src={star} />}
      <div className="button21" style={button1Style}>
        {label}
      </div>
      {hasIconEnd && <img className="x-icon7" alt="" src={x} />}
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  star: PropTypes.string,
  label: PropTypes.string,
  x: PropTypes.string,
  hasIconStart: PropTypes.bool,
  hasIconEnd: PropTypes.bool,

  /** Style props */
  propHeight: PropTypes.any,
  propBorderRadius: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propBorder: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propColor: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
  propFontWeight: PropTypes.any,
};

export default Button;
