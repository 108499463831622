import Button from "./Button";
import PropTypes from "prop-types";

const Card = ({
  className = "",
  communitySvgrepocom,
  safeCommunity,
  feelSaferKnowingThatYourNeighb,
  star,
  x,
  star1,
  label,
  x1,
  hasIconStart,
  hasIconEnd,
  propHeight,
  propBorderRadius,
  propBackgroundColor,
  propBorder,
  propAlignSelf,
  propColor,
  propDisplay,
  propMinWidth,
  propFontWeight,
}) => {
  return (
    <div className={`card4 ${className}`}>
      <img
        className="community-svgrepocom-icon"
        loading="lazy"
        alt=""
        src={communitySvgrepocom}
      />
      <div className="body4">
        <div className="text4">
          <h3 className="safe-community">{safeCommunity}</h3>
          <div className="feel-safer-knowing">
            {feelSaferKnowingThatYourNeighb}
          </div>
        </div>
        <div className="button-group4">
          <div className="button18">
            <img className="star-icon6" alt="" src={star} />
            <div className="button19">Button</div>
            <img className="x-icon6" alt="" src={x} />
          </div>
          <Button
            star={star1}
            label={label}
            x={x1}
            hasIconStart={hasIconStart}
            hasIconEnd={hasIconEnd}
            propHeight={propHeight}
            propBorderRadius={propBorderRadius}
            propBackgroundColor={propBackgroundColor}
            propBorder={propBorder}
            propAlignSelf={propAlignSelf}
            propColor={propColor}
            propDisplay={propDisplay}
            propMinWidth={propMinWidth}
            propFontWeight={propFontWeight}
          />
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  communitySvgrepocom: PropTypes.string,
  safeCommunity: PropTypes.string,
  feelSaferKnowingThatYourNeighb: PropTypes.string,
  star: PropTypes.string,
  x: PropTypes.string,
  star1: PropTypes.string,
  label: PropTypes.string,
  x1: PropTypes.string,
  hasIconStart: PropTypes.bool,
  hasIconEnd: PropTypes.bool,
  propHeight: PropTypes.string,
  propBorderRadius: PropTypes.string,
  propBackgroundColor: PropTypes.string,
  propBorder: PropTypes.string,
  propAlignSelf: PropTypes.string,
  propColor: PropTypes.string,
  propDisplay: PropTypes.string,
  propMinWidth: PropTypes.string,
  propFontWeight: PropTypes.string,
};

export default Card;
