import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as CloseButton } from "../images/close-button.svg";
import Quote from "./Quote";
import { Publish } from "@mui/icons-material";
import UrgencyPill from "./UrgencyPill";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  description,
  urgency,
  image,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // Create a URL for the image if it exists
  const imageUrl = image ? URL.createObjectURL(image) : null;

  const handleConfirmClick = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Box padding={3}>
        <div className={"close-button"} onClick={onClose}>
          <CloseButton />
        </div>
        <DialogTitle align={"center"}>Publish your ask?</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mt={2}>
            <UrgencyPill urgency={urgency} />
          </Box>
          <Quote text={description} />
          {imageUrl && (
            <Box mt={2} display="flex" justifyContent="center">
              <img
                src={imageUrl} // Display the actual image
                alt="Request"
                style={{ borderRadius: "8px", width: "100%" }}
              />
            </Box>
          )}
          <Typography variant="body2" mt={2}>
            Once published, this will send a text to everyone if your community.
            Once they respond, you'll receive an text back with details of their
            response.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "150%",
              display: "flex",
              padding: "13px 25px",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "#576DF3",
              textTransform: "none",
              width: "100%",
            }}
            startIcon={
              isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Publish />
              )
            }
          >
            {isLoading ? "Publishing..." : "Publish"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
