import React, { useEffect, useState } from "react";
import { useGlobal } from "../contexts/GlobalContext";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleAsk, sendResponse } from "../api/mainApi";
import DashboardHeader from "./DashboardHeader";
import { ReactComponent as BackButton } from "../images/back-button.svg";
import { formatDate, formatUSPhoneNumber } from "./utils";
import { ReactComponent as CloseButton } from "../images/close-button.svg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import SendSMSButton from "./buttons/SendSMSButton";
import Quote from "./Quote";
import {
  Check,
  ThumbUpAlt,
  VolunteerActivism,
  Close as CloseIcon,
  Edit as EditIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import UrgencyPill from "./UrgencyPill";
import OfferHelpDialog from "./OfferHelpDialog";
import PageTitle from "./PageTitle";

const Container = styled("div")({
  padding: "20px",
});

const ImageContainer = styled("div")({
  display: "flex",
  maxWidth: "80%",
  height: "auto",
  alignItems: "center",
  gap: "10px",
  borderRadius: "30px",
});

const HelpButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#576DF3",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  "&:hover": {
    background: "#3D5AFE",
  },
});

const CloseAskButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#FF6B6B",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  "&:hover": {
    background: "#FF4F4F",
  },
});

const EditButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#4CAF50",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  "&:hover": {
    background: "#45A049",
  },
});

const SaveButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#2196F3",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  "&:hover": {
    background: "#1E88E5",
  },
});

const ShareButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#FF9800",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  "&:hover": {
    background: "#F57C00",
  },
});

const ViewAskDetails = () => {
  const { id } = useParams();
  const [askDetails, setAskDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [closeAskDialogOpen, setCloseAskDialogOpen] = useState(false);
  const [offerHelpDialogOpen, setOfferHelpDialogOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { globalState, updateGlobalState } = useGlobal();

  const handleBack = () => {
    navigate("/home");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOfferHelpClick = () => {
    setOfferHelpDialogOpen(true);
  };

  const handleOfferHelpClose = () => {
    setOfferHelpDialogOpen(false);
  };

  const handleOfferHelpConfirm = async (note) => {
    try {
      const response = await sendResponse(id, note);

      const {
        includes: {
          ask: {
            includes: {
              author: {
                attributes: {
                  name,
                  last_name,
                  address,
                  address_2,
                  city,
                  state,
                  zip,
                  phone,
                },
              },
            },
          },
        },
      } = response.data;

      setOfferHelpDialogOpen(false);
      navigate("/response-confirmation", {
        state: {
          name,
          lastName: last_name,
          address: `${address}${address_2 ? `, ${address_2}` : ""}, ${city}, ${state} ${zip}`,
          phone,
        },
      });
    } catch (error) {
      console.error("Error confirming response:", error);
    }
  };

  const handleCloseAskClick = () => {
    setCloseAskDialogOpen(true);
  };

  const handleCloseAskDialogClose = () => {
    setCloseAskDialogOpen(false);
  };

  const handleConfirmCloseAsk = async () => {
    setIsClosing(true);
    try {
      const response = await fetch(`${globalState.apiUrl}/asks/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          data: {
            attributes: {
              status: "Completed",
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to close the ask");
      }

      const updatedAsk = await response.json();
      setAskDetails((prevDetails) => ({
        ...prevDetails,
        attributes: {
          ...prevDetails.attributes,
          status: "Completed",
        },
      }));
      setCloseAskDialogOpen(false);
      // You might want to show a success message here
    } catch (error) {
      console.error("Error closing ask:", error);
      // You might want to show an error message here
    } finally {
      setIsClosing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedDescription(askDetails.attributes.description);
    setDescriptionError("");
  };

  const handleSaveClick = async () => {
    if (!editedDescription.trim()) {
      setDescriptionError("Description cannot be empty");
      return;
    }

    setIsSaving(true);
    try {
      const response = await fetch(`${globalState.apiUrl}/asks/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          data: {
            attributes: {
              description: editedDescription.trim(),
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update the ask description");
      }

      const updatedAsk = await response.json();
      setAskDetails((prevDetails) => ({
        ...prevDetails,
        attributes: {
          ...prevDetails.attributes,
          description: editedDescription.trim(),
        },
      }));
      setIsEditing(false);
      setDescriptionError("");
    } catch (error) {
      console.error("Error updating ask description:", error);
      setDescriptionError("Failed to update description. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleShare = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isOwner = user.id === askDetails.includes.author.id;
    const ownerName = `${askDetails.includes.author.attributes.name} ${askDetails.includes.author.attributes.last_name}`;

    const truncatedDescription =
      askDetails.attributes.description.length > 100
        ? askDetails.attributes.description.substring(0, 97) + "..."
        : askDetails.attributes.description;

    const askLink = `${globalState.baseUrl}/asks/${id}`;

    let shareIntro;
    let shareMessage;
    if (isOwner) {
      shareIntro = `Hey, I have `;
    } else {
      shareIntro = `Hey, ${ownerName} has `;
    }

    shareMessage =
      shareIntro +
      `an ask on CuppaSugar, can you help? Description: "${truncatedDescription}". Respond here: ${askLink}`;

    const encodedMessage = encodeURIComponent(shareMessage);
    window.location.href = `sms:?&body=${encodedMessage}`;
  };

  useEffect(() => {
    const fetchAsk = async () => {
      try {
        const data = await fetchSingleAsk(id);
        setAskDetails(data.data);
      } catch (error) {
        console.error("Error fetching ask details:", error);
      }
    };

    fetchAsk();
  }, [id]);

  if (!askDetails) {
    return (
      <div className={"loading-state"}>
        <CircularProgress />
      </div>
    );
  }

  const user = JSON.parse(localStorage.getItem("user"));

  const {
    attributes: { description, photoUrl, timeline, status, createdAt },
    includes: {
      author,
      responses = [],
      category: {
        attributes: { name: categoryName },
      },
    },
  } = askDetails;

  const {
    attributes: { name, last_name, profilePhotoUrl },
  } = author;

  const isOwner = user.id === author.id;

  return (
    <Container sx={{ padding: "16px" }}>
      <DashboardHeader />
      <PageTitle title="Ask Details" backRoute="/home" />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <Avatar
          alt={name}
          src={profilePhotoUrl}
          sx={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
          }}
        />
        <Box display="flex" gap={2}>
          <UrgencyPill urgency={timeline} />
          {status === "Incomplete" ? (
            <div className="open-status">Open</div>
          ) : (
            <div className="closed-status">Closed</div>
          )}
        </Box>

        <div className={"ask-details-container"}>
          <div className={"ask-date"}> {formatDate(createdAt)}</div>
          <div className={"ask-user-text"}>
            <strong>{`${name} ${last_name} `}</strong>
            is asking for help with {` ${categoryName}`}
          </div>
          {isEditing ? (
            <TextField
              sx={{ mt: "10px" }}
              multiline
              rows={4}
              value={editedDescription}
              onChange={(e) => {
                setEditedDescription(e.target.value);
                if (e.target.value.trim()) {
                  setDescriptionError("");
                }
              }}
              fullWidth
              variant="outlined"
              error={!!descriptionError}
              helperText={descriptionError}
              required
            />
          ) : (
            <Quote text={description} />
          )}
        </div>
        {photoUrl && (
          <ImageContainer>
            <img
              src={photoUrl}
              alt="Ask detail"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          </ImageContainer>
        )}

        {status === "Completed" && (
          <Alert severity="info" sx={{ width: "100%" }}>
            This ask is now closed and has stopped accepting responses.
          </Alert>
        )}

        {!isOwner && status !== "Completed" && (
          <>
            <HelpButton
              onClick={handleOfferHelpClick}
              startIcon={<VolunteerActivism />}
            >
              Offer Help
            </HelpButton>
            <ShareButton onClick={handleShare} startIcon={<ShareIcon />}>
              Share Ask
            </ShareButton>
          </>
        )}

        {isOwner && status !== "Completed" && (
          <>
            {isEditing ? (
              <SaveButton
                onClick={handleSaveClick}
                startIcon={<Check />}
                disabled={isSaving || !editedDescription.trim()}
              >
                {isSaving ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Save Changes"
                )}
              </SaveButton>
            ) : (
              <>
                <ShareButton onClick={handleShare} startIcon={<ShareIcon />}>
                  Share Ask
                </ShareButton>
                <EditButton onClick={handleEditClick} startIcon={<EditIcon />}>
                  Edit Description
                </EditButton>
                <CloseAskButton
                  onClick={handleCloseAskClick}
                  startIcon={<CloseIcon />}
                  disabled={isClosing || isEditing}
                >
                  {isClosing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Close Ask"
                  )}
                </CloseAskButton>
              </>
            )}
          </>
        )}
      </Container>

      <Container>
        <Typography
          align="left"
          sx={{
            color: "#000",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "normal",
          }}
        >
          Responses
        </Typography>
        {responses.length > 0 ? (
          <List>
            {responses.map((response) => {
              const author = response.includes?.author;
              const authorAttributes = author?.attributes;

              if (!author || !authorAttributes) {
                return null;
              }

              return (
                <React.Fragment key={response.id}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      display: "flex",
                      padding: "16px 0px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "8px",
                      alignSelf: "stretch",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        width: "100%",
                      }}
                    >
                      <Avatar
                        alt={authorAttributes.name}
                        src={authorAttributes.profilePhotoUrl}
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "20px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            opacity: "var(--sds-size-stroke-border)",
                            color: "#1A1F36",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          <strong>{`${authorAttributes.name} ${authorAttributes.last_name} `}</strong>{" "}
                          offered help:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor: "#f5f5f5",
                            borderLeft: "4px solid #1A1F36",
                            borderRadius: "4px",
                            padding: "16px",
                            margin: "5px 0",
                          }}
                        >
                          <FormatQuoteIcon
                            sx={{
                              fontSize: 40,
                              color: "#1A1F36",
                              marginRight: "12px",
                            }}
                          />
                          <Typography
                            sx={{
                              opacity: "var(--sds-size-stroke-border)",
                              color: "#1A1F36",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {response.attributes.message}
                          </Typography>
                        </Box>
                        {isOwner && (
                          <Typography
                            sx={{
                              opacity: "var(--sds-size-stroke-border)",
                              color: "#1A1F36",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            <strong>Address: </strong>
                            {authorAttributes.address}
                          </Typography>
                        )}
                        {isOwner && (
                          <Typography
                            sx={{
                              opacity: "var(--sds-size-stroke-border)",
                              color: "#1A1F36",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            <strong>Phone: </strong>
                            {formatUSPhoneNumber(authorAttributes.phone)}
                          </Typography>
                        )}
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="span"
                              >
                                Responded{" "}
                                {formatDate(response.attributes.createdAt)}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        {isOwner && (
                          <SendSMSButton phoneNumber={authorAttributes.phone} />
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{
              marginTop: "16px",
              display: "flex",
              padding: "24px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "24px",
              borderRadius: "20px",
              opacity: 1,
              background: "#FFF",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.09)",
              color: "#848484",
              fontFamily: "Inter, sans-serif",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            No one has responded to this ask yet, be the first!
          </Typography>
        )}
      </Container>

      {/* Modal Dialog for Offering Help */}
      <OfferHelpDialog
        open={offerHelpDialogOpen}
        onClose={handleOfferHelpClose}
        onConfirm={handleOfferHelpConfirm}
      />

      {/* Modal Dialog for Closing Ask */}
      <Dialog open={closeAskDialogOpen} onClose={handleCloseAskDialogClose}>
        <DialogTitle>Close Ask</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to close this Ask and stop accepting
            responses?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAskDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmCloseAsk} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ViewAskDetails;
