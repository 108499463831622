import React, { useState } from "react";
import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { sendPhoneNumber } from "../api/mainApi";
import { styled } from "@mui/system";
import USFlagIcon from "../images/united-states.png";

// Reusable styled button component
const StyledButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#576DF3",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  marginTop: "24px",
  "&:hover": {
    background: "#3D5AFE",
  },
});

const PhoneInputWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "1px solid #c4c4c4",
  borderRadius: "4px",
  padding: "8px 12px",
  marginTop: "10px",
  "&:focus-within": {
    borderColor: "#1976d2",
    boxShadow: "0 0 0 2px rgba(25,118,210,0.2)",
  },
});

const PrefixTypography = styled(Typography)({
  marginRight: "8px",
  fontWeight: "bold",
  color: "#333",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontWeight: "bold",
    fontSize: "18px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

// Adapt the formatUSPhoneNumber function for partial inputs
const formatPartialPhoneNumber = (value) => {
  const cleaned = value.replace(/\D/g, "");
  if (cleaned.length <= 3) return cleaned;
  if (cleaned.length <= 6)
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
};

const LoginPage = () => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    if (inputValue.length <= 10) {
      const formattedValue = formatPartialPhoneNumber(inputValue);
      setValue(formattedValue);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = async () => {
    const phoneNumber = value.replace(/\D/g, "");
    if (phoneNumber.length === 10) {
      setIsLoading(true);
      try {
        const message = await sendPhoneNumber(phoneNumber);
        console.log(message.data.code);
        navigate("/auth", {
          state: { phoneNumber: phoneNumber, code: message.data.code },
        });
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Box className="signup-container">
      <Typography variant="h5" className="signup-header" gutterBottom>
        Sign up or Sign in
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sign up or sign in using your phone number to connect with your
        neighbors and access community resources.
      </Typography>
      <PhoneInputWrapper>
        <InputAdornment position="start">
          <img
            src={USFlagIcon}
            alt="US Flag"
            style={{ width: 24, height: 24, marginRight: "8px" }}
          />
        </InputAdornment>
        <PrefixTypography>+1</PrefixTypography>
        <StyledTextField
          fullWidth
          value={value}
          onChange={handleChange}
          placeholder="(555) 555-5555"
          inputProps={{
            maxLength: 14,
            inputMode: "tel",
          }}
          error={error}
          disabled={isLoading}
        />
      </PhoneInputWrapper>
      {error && (
        <Typography color="error" variant="caption">
          Please enter a valid 10-digit US phone number
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 2 }}>
        We'll send a verification code to this number for security purposes.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        By continuing, you agree to our{" "}
        <Link to={"/terms"} target="_blank">
          Terms and Conditions
        </Link>{" "}
        and{" "}
        <Link to={"/privacy"} target="_blank">
          Privacy Policy
        </Link>
        .
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: ".8em", mt: 2, mb: 2 }}
      >
        Message frequency varies. Message and data rates may apply. Reply HELP
        for help. Reply STOP to opt out.
      </Typography>
      <StyledButton onClick={handleSubmit} fullWidth disabled={isLoading}>
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Get Verification Code"
        )}
      </StyledButton>
    </Box>
  );
};

export default LoginPage;
