import React, { useState } from "react";
import { ReactComponent as Logo } from "../images/cuppasugar-logo.svg";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Logout, Settings, AccountCircle } from "@mui/icons-material";

const DashboardHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!user) {
    return null;
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  const menuItems = [
    // {
    //   label: "Profile",
    //   icon: <AccountCircle />,
    //   action: () => navigate("/profile"),
    // },
    // {
    //   label: "Settings",
    //   icon: <Settings />,
    //   action: () => navigate("/settings"),
    // },
    { label: "Sign Out", icon: <Logout />, action: handleSignOut },
  ];

  return (
    <div
      className="feed-dashboard-header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="dashboard-logo"
        onClick={() => navigate("/home")}
        style={{ cursor: "pointer", width: "142px", display: "flex" }}
      >
        <Logo alt="CuppaSugar Logo" />
      </div>
      <div>
        <IconButton onClick={handleMenuOpen} size="large" edge="end">
          <Avatar
            alt="User Avatar"
            src={user.profilePhotoUrl || user.profile_photo_url}
            sx={{ width: 56, height: 56 }}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.action}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default DashboardHeader;
