import React from "react";
import { Button } from "@mui/material";
import { Sms } from "@mui/icons-material";

const SendSMSButton = ({ phoneNumber }) => {
  const handleSMSClick = () => {
    // Create the SMS link
    const smsLink = `sms:${phoneNumber}`;

    // Open the link
    window.location.href = smsLink;
  };

  return (
    <Button
      onClick={handleSMSClick}
      sx={{
        backgroundColor: "#FF6F00",
        color: "#FFF",
        borderRadius: "8px",
        padding: "8px 16px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#E65100",
        },
      }}
      startIcon={<Sms />}
    >
      Message
    </Button>
  );
};

export default SendSMSButton;
