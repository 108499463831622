import "./styles/App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { GlobalProvider } from "./contexts/GlobalContext";
import LoginPage from "./components/Login";
import VerificationPage from "./components/Verfication";
import Layout from "./components/Layout";
import SignUp from "./components/SignUp";
import Dashboard from "./components/DashBoard";
import Ask from "./components/Ask";
import LandingPageDesign from "./components/landing/LandingPageDesign";
import AskConfirmation from "./components/AskConfirmation";
import AskDetails from "./components/AskDetails";
import ResponseConfirmation from "./components/ResponseConfirmation";
import useInviteCode from "./hooks/useInviteCode";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import ProfileCompletionGuard from "./components/routes/ProfileCompletionGuard";
import AuthGuard from "./components/routes/AuthGuard";
import GeneralGuard from "./components/routes/GeneralGuard";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Community from "./components/Community";

const App = () => {
  return (
    <GlobalProvider>
      <Router>
        <InviteCodeWrapper />
        <Routes>
          <Route path="/" element={<LandingPageDesign />} />

          <Route element={<Layout />}>
            <Route
              path="/login"
              element={
                <AuthGuard>
                  <LoginPage />
                </AuthGuard>
              }
            />
            <Route path="/auth" element={<VerificationPage />} />
            <Route
              path="/onboarding/*"
              element={
                <GeneralGuard>
                  <ProtectedRoute>
                    <SignUp />
                  </ProtectedRoute>
                </GeneralGuard>
              }
            />
          </Route>
          <Route path="/ask/create" element={<Ask />} />
          <Route path="/asks/:id" element={<AskDetails />} />
          <Route
            path="/home"
            element={
              <ProfileCompletionGuard>
                <Dashboard />
              </ProfileCompletionGuard>
            }
          />
          <Route path="/community" element={<Community />} />
          <Route
            path="/response-confirmation"
            element={<ResponseConfirmation />}
          />
          <Route path="/ask/confirmation" element={<AskConfirmation />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
};

const InviteCodeWrapper = () => {
  useInviteCode();
  return null; // This component doesn't render anything
};

export default App;
