import React from "react";
import DashboardHeader from "./DashboardHeader";
import HappyFace from "../images/happy-face";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AskConfirmation = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home");
  };
  return (
    <div className={"ask-confirmation-container"}>
      <DashboardHeader />
      <div className={"happy-face"}>
        <HappyFace />
      </div>
      <div className={"congrats-text"}>
        Congratulations &#127881; You sent an ask to your community.
      </div>
      <div className={"congrats-details"}>
        Now we wait. Shortly you will receive a text message with from someone
        in your community that is willing to help!
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{
          color: "#FFF",
          fontFamily: "Inter, sans-serif",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "150%",
          display: "flex",
          padding: "13px 25px",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "12px",
          background: "#576DF3",
          margin: "0px 32px",
          textTransform: "none",
        }}
      >
        Return to homepage
      </Button>
    </div>
  );
};

export default AskConfirmation;
