import React, { useState } from "react";
import { TextField, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SignUpHeader from "./SignUpHeader";

const StyledButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#576DF3",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  marginTop: "24px",
  "&:hover": {
    background: "#3D5AFE",
  },
});

const PersonalInfoForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = "First Name is required";
    if (!lastName.trim()) newErrors.lastName = "Last Name is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    localStorage.setItem("firstName", firstName.trim());
    localStorage.setItem("lastName", lastName.trim());
    navigate("/onboarding/address");
  };

  return (
    <Box
      className="personal-info-container"
      sx={{ maxWidth: 400, margin: "auto", padding: 3 }}
    >
      <SignUpHeader />
      <Typography
        variant="h5"
        className="personal-info-header"
        gutterBottom
        align="center"
      >
        Full Name
      </Typography>
      <form onSubmit={handleSubmit} className="signup-container">
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <TextField
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <StyledButton type="submit" fullWidth>
          Continue
        </StyledButton>
      </form>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        sx={{ mt: 2 }}
      >
        Your privacy matters to us. Initially, only your first name and last
        initial will be visible to your community. Your full name will be shared
        only with neighbors you directly interact with through asks or
        responses.
      </Typography>
    </Box>
  );
};

export default PersonalInfoForm;
