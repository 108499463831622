import React from "react";
import { Navigate } from "react-router-dom";

const ProfileCompletionGuard = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Check if the user has completed their profile
  if (!user.name || !user.last_name) {
    return <Navigate to="/onboarding/name" replace />;
  }

  if (!user.address || !user.city || !user.state || !user.zip) {
    return <Navigate to="/onboarding/address" replace />;
  }

  return children;
};

export default ProfileCompletionGuard;
