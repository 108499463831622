import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const AboveTheFold = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <section className={`above-the-fold ${className}`}>
      <div className="hero-content">
        <div className="contact-icons">
          <img
            className="contact-icons-child image"
            alt=""
            src="/group-32@2x.png"
          />
          <img
            className="contact-icons-item image"
            loading="lazy"
            alt=""
            src="/group-31.svg"
          />
          <img
            className="contact-icons-inner image"
            alt=""
            src="/group-33.svg"
          />
          <img
            className="chat-square-call-svgrepocom-icon image"
            loading="lazy"
            alt=""
            src="/chatsquarecall-svgrepocom.svg"
          />
          <img
            className="chat-square-call-svgrepocom-icon1 image"
            loading="lazy"
            alt=""
            src="/chatsquarecall-svgrepocom-1.svg"
          />
          <div className="image-container">
            <img className="hands" loading="lazy" alt="" src="/hands.jpeg" />
          </div>
        </div>
        <div className="request-a-hand-parent">
          <div className="request-a-hand">Request a hand</div>
          <div className="ellipse-parent">
            <img className="frame-child" alt="" src="/ellipse-17@2x.png" />
            <img className="frame-item" alt="" src="/ellipse-15@2x.png" />
            <img
              className="frame-inner"
              loading="lazy"
              alt=""
              src="/ellipse-18@2x.png"
            />
          </div>
          <div className="or-lend-one">Or lend one with a few clicks.</div>
          <div className="ellipse-parent-2">
            <img className="frame-child" alt="" src="/ellipse-17@2x.png" />
            <img className="frame-item" alt="" src="/ellipse-15@2x.png" />
            <img
              className="frame-inner"
              loading="lazy"
              alt=""
              src="/ellipse-18@2x.png"
            />
          </div>
          <img
            className="arrow-up-right-svgrepocom-icon"
            loading="lazy"
            alt="Navigate to Home"
            src="/arrowupright-svgrepocom.svg"
            onClick={() => {
              navigate("/home");
            }} // Add the onClick handler
            style={{ cursor: "pointer" }} // Optional: Change cursor to pointer
          />
          <div
            className={"get-started"}
            onClick={() => {
              navigate("/home");
            }} // Add the onClick handler
          >
            Get Started
          </div>
        </div>
      </div>
    </section>
  );
};

AboveTheFold.propTypes = {
  className: PropTypes.string,
};

export default AboveTheFold;
