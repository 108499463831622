import React, { useRef, useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { loginUser } from "../api/mainApi";
import { useNavigate, useLocation } from "react-router-dom";
import { formatUSPhoneNumber } from "./utils";

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#576DF3",
  color: "#FFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  marginTop: "24px",
  "&:hover": {
    background: "#3D5AFE",
  },
}));

const DigitInput = styled(TextField)(({ theme }) => ({
  width: "48px",
  height: "64px",
  margin: "0 4px",
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "56px",
    margin: "0 2px",
  },
  "& input": {
    textAlign: "center",
    fontSize: "28px",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      padding: "4px 0",
    },
  },
}));

const VerificationPage = () => {
  const [code, setCode] = useState(Array(6).fill(""));
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || "";
  const initialCode = location.state?.code?.toString() || "";

  useEffect(() => {
    if (initialCode) {
      setCode(initialCode.split(""));
      inputRefs.current[5]?.focus();
    }
  }, [initialCode]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newCode = [...code];

    if (value.length > 1) {
      // Handle pasting of entire code or auto-fill
      const codeArray = value
        .split("")
        .slice(0, 6)
        .filter((char) => /^\d$/.test(char));
      for (let i = 0; i < 6; i++) {
        newCode[i] = codeArray[i] || "";
      }
      setCode(newCode);
      if (codeArray.length === 6) {
        inputRefs.current[5]?.focus();
      } else {
        inputRefs.current[Math.min(codeArray.length, 5)]?.focus();
      }
    } else {
      // Handle single digit input
      if (/^\d?$/.test(value)) {
        newCode[index] = value;
        setCode(newCode);
        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if ((e.key === "Backspace" || e.key === "Delete") && !code[index]) {
      e.preventDefault();
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("text").slice(0, 6);
    if (/^\d{6}$/.test(pastedCode)) {
      setCode(pastedCode.split(""));
      inputRefs.current[5]?.focus();
    }
    e.preventDefault();
  };

  const handleSubmit = async () => {
    const enteredCode = code.join("");
    setIsLoading(true);
    setError(false);
    setErrorMessage("");

    try {
      const response = await loginUser(phoneNumber, enteredCode);
      if (response.status === 200) {
        const {
          attributes,
          meta: { authToken },
          includes: { community },
          id,
        } = response.data;
        attributes.id = id;

        localStorage.setItem("token", authToken);
        localStorage.setItem("userId", id);
        localStorage.setItem("user", JSON.stringify(attributes));
        localStorage.setItem("communityId", community?.id);

        if (
          !attributes.name ||
          !attributes.last_name ||
          !attributes.address ||
          !attributes.city ||
          !attributes.state ||
          !attributes.zip
        ) {
          navigate("/onboarding/name");
        } else {
          navigate("/home");
        }
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("The code you entered is incorrect or the login failed.");
      console.error("Failed to login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      className="verification-container"
      sx={{ maxWidth: 400, margin: "auto", padding: 3 }}
    >
      <Typography variant="h5" gutterBottom align="center">
        Verification Code
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Enter the 6-digit code we sent to {formatUSPhoneNumber(phoneNumber)}
      </Typography>
      <Box
        className="digit-container"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          my: 3,
          "& > *": { mb: 1 },
        }}
      >
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <DigitInput
            key={index}
            value={code[index]}
            inputProps={{
              maxLength: index === 0 ? 6 : 1,
              pattern: "[0-9]*",
              inputMode: "numeric",
              type: "tel",
            }}
            onChange={(e) => handleInputChange(e, index)}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            variant="outlined"
            error={error}
            disabled={isLoading}
          />
        ))}
      </Box>

      {error && (
        <Typography color="error" variant="body2" align="center" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}

      <StyledButton onClick={handleSubmit} fullWidth disabled={isLoading}>
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Verify and Continue"
        )}
      </StyledButton>

      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        sx={{ mt: 2 }}
      >
        By verifying your phone number, you agree to our Terms of Service and
        Privacy Policy.
      </Typography>
    </Box>
  );
};

export default VerificationPage;
