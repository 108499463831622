import { Box } from "@mui/material";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import React from "react";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: "20px",
      }}
      className="privacy-Policy"
    >
      <Layout />
      <h1 id="cuppasugar-privacy-policy">CuppaSugar Privacy Policy</h1>
      <h2 id="1-introduction">1. Introduction</h2>
      <p>
        Welcome to CuppaSugar. We are committed to protecting your privacy and
        ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy outlines how we collect, use,
        and protect your information when you use our platform.
      </p>
      <h2 id="2-information-we-collect">2. Information We Collect</h2>
      <h3 id="2-1-personal-information">2.1 Personal Information</h3>
      <p>
        When you register for CuppaSugar, we collect the following personal
        information:
      </p>
      <ul>
        <li>
          <strong>Name</strong>: To identify you within the platform.
        </li>
        <li>
          <strong>Phone Number</strong>: Used for account verification and to
          send SMS notifications.
        </li>
        <li>
          <strong>Email Address</strong>: Optional, used for communication and
          account management.
        </li>
        <li>
          <strong>Physical Address</strong>: Shared with neighbors to facilitate
          requests and responses.
        </li>
        <li>
          <strong>Profile Information</strong>: Any additional information you
          choose to provide in your profile.
        </li>
      </ul>
      <h3 id="2-2-usage-data">2.2 Usage Data</h3>
      <p>
        We collect information about how you interact with the CuppaSugar
        platform, including:
      </p>
      <ul>
        <li>
          <strong>Requests and Responses</strong>: Details of the requests you
          make and responses you provide.
        </li>
        <li>
          <strong>Interactions</strong>: Information about how you engage with
          other users, such as messages sent and received.
        </li>
        <li>
          <strong>Device Information</strong>: Data about the device you use to
          access our platform, including IP address, browser type, and operating
          system.
        </li>
      </ul>
      <h3 id="2-3-data-collected-through-hotjar">
        2.3 Data Collected Through Hotjar
      </h3>
      <p>
        We use Hotjar to understand how users interact with our platform. Hotjar
        collects information such as:
      </p>
      <ul>
        <li>
          <strong>Clicks and Taps</strong>: Where you click or tap on the
          platform.
        </li>
        <li>
          <strong>Mouse Movements</strong>: How you move your mouse or scroll on
          the platform.
        </li>
        <li>
          <strong>Session Duration</strong>: How long you spend on each page of
          the platform.
        </li>
        <li>
          <strong>Page Interactions</strong>: Which features you interact with
          and how.
        </li>
      </ul>
      <p>
        Hotjar may also collect other data that you voluntarily enter during
        your interactions with the platform.
      </p>
      <h2 id="3-how-we-use-your-information">3. How We Use Your Information</h2>
      <h3 id="3-1-to-operate-and-improve-the-platform">
        3.1 To Operate and Improve the Platform
      </h3>
      <p>We use your personal information to:</p>
      <ul>
        <li>
          <strong>Facilitate Requests and Responses</strong>: Share your
          information with neighbors to fulfill requests and responses.
        </li>
        <li>
          <strong>Communicate with You</strong>: Send SMS notifications about
          requests, responses, and important updates.
        </li>
        <li>
          <strong>Improve User Experience</strong>: Analyze usage data to
          enhance the functionality and usability of the platform.
        </li>
        <li>
          <strong>Monitor and Analyze Trends</strong>: Use Hotjar data to
          understand user behavior and improve the platform.
        </li>
      </ul>
      <h3 id="3-2-to-ensure-compliance">3.2 To Ensure Compliance</h3>
      <p>
        We use your information to ensure compliance with our Terms and
        Conditions, including verifying your identity and monitoring for
        fraudulent activity.
      </p>
      <h3 id="3-3-to-personalize-your-experience">
        3.3 To Personalize Your Experience
      </h3>
      <p>
        We may use your information to personalize your experience on the
        platform, such as by suggesting relevant content or features based on
        your activity.
      </p>
      <h3 id="3-4-to-send-marketing-communications">
        3.4 To Send Marketing Communications
      </h3>
      <p>
        With your consent, we may send you marketing communications about new
        features, updates, or special offers. You can opt out of these
        communications at any time.
      </p>
      <h2 id="4-sharing-your-information">4. Sharing Your Information</h2>
      <h3 id="4-1-with-your-neighbors">4.1 With Your Neighbors</h3>
      <p>
        Your name, phone number, and physical address will be shared with your
        neighbors through the CuppaSugar platform to facilitate requests and
        responses.
      </p>
      <h3 id="4-2-with-third-party-service-providers">
        4.2 With Third-Party Service Providers
      </h3>
      <p>
        We may share your information with third-party service providers who
        assist us in operating our platform, such as:
      </p>
      <ul>
        <li>
          <strong>Hotjar</strong>: For user behavior analysis.
        </li>
        <li>
          <strong>SMS Providers</strong>: To send SMS notifications.
        </li>
      </ul>
      <p>
        These service providers are required to protect your information and
        only use it for the purposes specified by us.
      </p>
      <h3 id="4-3-in-compliance-with-legal-obligations">
        4.3 In Compliance with Legal Obligations
      </h3>
      <p>
        We may disclose your information if required to do so by law or in
        response to a legal request, such as a subpoena or court order.
      </p>
      <h3 id="4-4-in-case-of-business-transfers">
        4.4 In Case of Business Transfers
      </h3>
      <p>
        In the event of a merger, acquisition, or sale of all or a portion of
        our assets, your information may be transferred as part of that
        transaction.
      </p>
      <h2 id="5-data-security">5. Data Security</h2>
      <p>
        We take reasonable measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction. However, no
        method of transmission over the internet or electronic storage is 100%
        secure, and we cannot guarantee absolute security.
      </p>
      <h2 id="6-data-retention">6. Data Retention</h2>
      <p>
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy, unless a longer retention
        period is required or permitted by law.
      </p>
      <h2 id="7-your-rights-and-choices">7. Your Rights and Choices</h2>
      <h3 id="7-1-access-and-update-your-information">
        7.1 Access and Update Your Information
      </h3>
      <p>
        You can access and update your personal information at any time through
        your account settings.
      </p>
      <h3 id="7-2-opt-out-of-communications">7.2 Opt Out of Communications</h3>
      <p>
        You can opt out of receiving SMS notifications or marketing
        communications by following the instructions provided in the messages or
        by contacting us directly.
      </p>
      <h3 id="7-3-request-deletion-of-your-data">
        7.3 Request Deletion of Your Data
      </h3>
      <p>
        You have the right to request the deletion of your personal information.
        Please note that deleting your information may affect your ability to
        use the CuppaSugar platform.
      </p>
      <h2 id="8-cookies-and-tracking-technologies">
        8. Cookies and Tracking Technologies
      </h2>
      <p>
        CuppaSugar uses cookies and similar tracking technologies to enhance
        your experience on our platform. These technologies may collect
        information about your browsing habits and preferences.
      </p>
      <h3 id="8-1-cookies">8.1 Cookies</h3>
      <p>
        Cookies are small data files stored on your device that help us
        understand how you use the platform. You can manage your cookie
        preferences through your browser settings.
      </p>
      <h3 id="8-2-tracking-technologies">8.2 Tracking Technologies</h3>
      <p>
        We use tracking technologies such as pixels and tags to collect
        information about your interactions with our platform and our
        communications.
      </p>
      <h2 id="9-children-s-privacy">9. Children’s Privacy</h2>
      <p>
        CuppaSugar is not intended for use by children under the age of 18. We
        do not knowingly collect personal information from children under 18. If
        we become aware that we have collected personal information from a child
        under 18, we will take steps to delete such information.
      </p>
      <h2 id="10-changes-to-this-privacy-policy">
        10. Changes to This Privacy Policy
      </h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will notify you of any significant changes by posting the updated
        policy on our platform and, if necessary, via SMS or email.
      </p>
      <h2 id="-11-mobile-opt-in-data-">11. Mobile Opt-In Data</h2>
      <p>
        By opting into SMS notifications, you agree to receive messages related
        to your use of the CuppaSugar platform. We want to assure you that your
        mobile opt-in data, including your phone number, will{" "}
        <strong>not</strong> be shared with third parties, except as required to
        provide the service (e.g., SMS delivery providers) or as required by
        law.
      </p>
      <p>
        Before you opt into our SMS notifications, please note that your mobile
        number and any other personal information you provide will be used
        solely for the purposes of delivering the services you request on the
        CuppaSugar platform. We respect your privacy, and your data will not be
        shared with third parties without your consent, except as outlined in
        this Privacy Policy.{" "}
      </p>
      <p>
        By opting in, you agree to our{" "}
        <a href="" onClick={() => navigate("/terms")} target="_blank">
          Terms and Conditions
        </a>{" "}
        and acknowledge that you have read and understood our Privacy Policy.
      </p>

      <h2 id="11-contact-us">12. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at support@cuppasugar.io. CuppaSugar (KHAMLICHI LLC), 1501
        Lincoln Blvd. #1053, Venice, CA 90291.
      </p>
    </Box>
  );
};

export default PrivacyPolicy;
