import React from "react";
import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { useLocation } from "react-router-dom";

const StyledStepper = styled(Stepper)({
  width: "100%",
  "@media (max-width: 600px)": {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "16px 8px",
  },
});

const StepIcon = styled(Box)(({ ownerState }) => ({
  width: 40,
  height: 40,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: ownerState.completed || ownerState.active ? "#576DF3" : "#E0E0E0",
  color: ownerState.completed || ownerState.active ? "#FFFFFF" : "#000000",
  transition: "all 0.3s ease",
}));

const StepConnector = styled(Box)(({ ownerState }) => ({
  width: "100%",
  height: 2,
  background: ownerState.completed ? "#576DF3" : "#E0E0E0",
  "@media (max-width: 600px)": {
    width: 2,
    height: 20,
    margin: "0 auto",
  },
}));

const steps = [
  { label: "Full Name", icon: <AccountCircleIcon />, path: "/onboarding/name" },
  { label: "Address", icon: <HomeIcon />, path: "/onboarding/address" },
  { label: "Welcome", icon: <EmojiPeopleIcon />, path: "/onboarding/welcome" },
];

const SignUpHeader = () => {
  const location = useLocation();
  const currentStepIndex = steps.findIndex(
    (step) => step.path === location.pathname,
  );

  return (
    <Box sx={{ width: "100%", mb: 4 }}>
      <StyledStepper activeStep={currentStepIndex} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() => (
                <StepIcon
                  ownerState={{
                    completed: index < currentStepIndex,
                    active: index === currentStepIndex,
                  }}
                >
                  {step.icon}
                </StepIcon>
              )}
            >
              <Typography
                variant="caption"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {index < steps.length - 1 && (
              <StepConnector
                ownerState={{
                  completed: index < currentStepIndex,
                }}
              />
            )}
          </Step>
        ))}
      </StyledStepper>
    </Box>
  );
};

export default SignUpHeader;
