import Card from "./Card";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const CardGridIcon1 = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <section className={`card-grid-icon1 ${className}`}>
      <div className="dashboard-content">
        <img
          src={"/preview-app.png"}
          alt={"preview"}
          className={"preview-image"}
        />
        <div className="community-container-wrapper">
          <div className="community-container">
            <div className="text-content-heading">
              <h1 className="join-our-community">What is CuppaSugar?</h1>
              <div className="subheading3">
                CuppaSugar is a safe way to share resources and get to know your
                neighbors along the way.
              </div>
            </div>
            <div className="subheading2">Key Benefits</div>
            <div className="community-cards">
              <div className="cards1">
                <Card
                  communitySvgrepocom="/community-svgrepocom.svg"
                  safeCommunity="Build a Safer Community"
                  feelSaferKnowingThatYourNeighb="Feel safer knowing that your neighbors are looking out for you and are ready to help when needed."
                  star="/star.svg"
                  x="/x.svg"
                  star1="/star.svg"
                  label="Button"
                  x1="/x.svg"
                  hasIconStart={false}
                  hasIconEnd={false}
                  propHeight="40px"
                  propBorderRadius="var(--radius-200)"
                  propBackgroundColor="#e3e3e3"
                  propBorder="1px solid #767676"
                  propAlignSelf="stretch"
                  propColor="#1e1e1e"
                  propDisplay="unset"
                  propMinWidth="unset"
                  propFontWeight="unset"
                />
                <Card
                  communitySvgrepocom="/eco-svgrepocom.svg"
                  safeCommunity="Become More Eco-Friendly"
                  feelSaferKnowingThatYourNeighb="Reduce waste by borrowing and lending items instead of buying new ones. Help the environment by making use of shared resources."
                  star="/star.svg"
                  x="/x.svg"
                  star1="/star.svg"
                  label="Button"
                  x1="/x.svg"
                  hasIconStart={false}
                  hasIconEnd={false}
                  propHeight="40px"
                  propBorderRadius="var(--radius-200)"
                  propBackgroundColor="#e3e3e3"
                  propBorder="1px solid #767676"
                  propAlignSelf="stretch"
                  propColor="#1e1e1e"
                  propDisplay="unset"
                  propMinWidth="unset"
                  propFontWeight="unset"
                />
              </div>
              <div className="cards2">
                <Card
                  communitySvgrepocom="/share-svgrepocom.svg"
                  safeCommunity="Save Money And Space"
                  feelSaferKnowingThatYourNeighb="Avoid using delivery services for small items and stop cluttering your home and yard."
                  star="/star.svg"
                  x="/x.svg"
                  star1="/star.svg"
                  label="Button"
                  x1="/x.svg"
                  hasIconStart={false}
                  hasIconEnd={false}
                  propHeight="40px"
                  propBorderRadius="var(--radius-200)"
                  propBackgroundColor="#e3e3e3"
                  propBorder="1px solid #767676"
                  propAlignSelf="stretch"
                  propColor="#1e1e1e"
                  propDisplay="unset"
                  propMinWidth="unset"
                  propFontWeight="unset"
                />
                <Card
                  communitySvgrepocom="/community-svgrepocom-1.svg"
                  safeCommunity="Create a Sense of Belonging"
                  feelSaferKnowingThatYourNeighb="Strengthen your sense of belonging by becoming an active part of your neighborhood. Build trust and connections through small acts of kindness."
                  star="/star.svg"
                  x="/x.svg"
                  star1="/star.svg"
                  label="Button"
                  x1="/x.svg"
                  hasIconStart={false}
                  hasIconEnd={false}
                  propHeight="40px"
                  propBorderRadius="var(--radius-200)"
                  propBackgroundColor="#e3e3e3"
                  propBorder="1px solid #767676"
                  propAlignSelf="stretch"
                  propColor="#1e1e1e"
                  propDisplay="unset"
                  propMinWidth="unset"
                  propFontWeight="unset"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CardGridIcon1.propTypes = {
  className: PropTypes.string,
};

export default CardGridIcon1;
