import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context
const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    appName: process.env.REACT_APP_NAME,
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    // Add other environment variables as needed
    user: null,
  });

  // Function to update global state
  const updateGlobalState = (key, value) => {
    setGlobalState((prevState) => ({ ...prevState, [key]: value }));
  };

  // // You can add effects here to load data from localStorage, API, etc.
  // useEffect(() => {
  //   // Example: Load user from localStorage
  //   const user = localStorage.getItem("user");
  //   if (user) {
  //     updateGlobalState("user", JSON.parse(user));
  //   }
  // }, []);

  return (
    <GlobalContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global context
export const useGlobal = () => useContext(GlobalContext);
