import React from "react";
import { Link, Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "../images/cuppasugar-logo.svg";

const Layout = () => {
  return (
    <div>
      <header className={"header-container"}>
        <Link to={"/"}>
          <Logo style={{ width: "142px" }} />
        </Link>
      </header>
      <main>
        <Outlet /> {/* Render the current route's component here */}
      </main>
    </div>
  );
};

export default Layout;
