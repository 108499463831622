import React from "react";

const HappyFace = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="190"
    height="191"
    viewBox="0 0 190 191"
    fill="none"
  >
    <path
      d="M18.3545 103.409C18.3545 168.269 88.5679 208.807 144.739 176.377C170.808 161.326 186.867 133.511 186.867 103.409C186.867 38.5483 116.653 -1.98952 60.4825 30.4408C34.4135 45.4916 18.3545 73.3069 18.3545 103.409Z"
      fill="#FFE55D"
    />
    <path
      d="M102.611 187.665C125.051 187.697 146.572 178.746 162.371 162.809C135.473 117.362 69.7235 117.311 42.7549 162.717C58.563 178.709 80.1236 187.696 102.611 187.665Z"
      fill="#149711"
    />
    <path
      d="M162.371 162.803C153.475 147.787 139.268 136.656 122.56 131.611H112.213C107.59 131.509 103.557 134.735 102.642 139.268C101.693 144.886 106.022 150.01 111.72 150.011C116.556 150.013 120.079 154.591 118.844 159.266C117.902 162.565 114.854 164.815 111.423 164.743H85.1524C76.1832 164.625 68.3052 170.677 66.1084 179.374C88.6191 190.162 114.748 190.44 137.484 180.134V178.544C137.485 176.51 139.134 174.862 141.168 174.862H147.277C152.752 171.432 157.817 167.386 162.371 162.803Z"
      fill="#5C92E9"
    />
    <path
      d="M162.371 162.801C135.473 117.353 69.7235 117.302 42.7549 162.708"
      stroke="black"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M162.371 162.803C153.475 147.787 139.268 136.656 122.56 131.611H112.213C107.59 131.509 103.557 134.735 102.642 139.268C101.693 144.886 106.022 150.01 111.72 150.011C116.556 150.013 120.079 154.591 118.844 159.266C117.902 162.565 114.854 164.815 111.423 164.743H85.1524C76.1832 164.625 68.3052 170.677 66.1084 179.374"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.484 180.13V178.544C137.485 176.51 139.134 174.862 141.168 174.862H147.277"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.3747 90.8503C41.433 86.5218 37.4088 82.671 33.1312 83.9187C32.4427 84.1194 31.7977 84.4463 31.2287 84.8829C25.439 78.0546 14.4288 80.0545 11.4101 88.4827C10.9585 89.7437 10.7281 91.0736 10.7292 92.4132V92.487C10.4896 92.4465 10.2475 92.4219 10.0047 92.4132C5.526 92.4052 2.71804 97.2484 4.95041 101.131C5.99023 102.939 7.91863 104.053 10.0047 104.049H36.1872C41.7764 104.059 45.2809 98.015 42.4952 93.1694C41.9674 92.2513 41.2409 91.4598 40.3747 90.8503Z"
      fill="white"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M182.408 35.5868C183.203 32.3424 180.188 29.4536 176.981 30.3867C176.463 30.5376 175.977 30.7838 175.549 31.1126C171.212 25.9888 162.955 27.4812 160.686 33.7991C160.345 34.7482 160.171 35.7493 160.172 36.7579V36.8126C159.991 36.7825 159.808 36.7643 159.625 36.7579C156.267 36.7577 154.168 40.3929 155.847 43.3011C156.626 44.6511 158.066 45.4827 159.625 45.4825H179.256C183.446 45.497 186.081 40.9702 183.998 37.3344C183.601 36.6399 183.055 36.0418 182.399 35.5824L182.408 35.5868Z"
      fill="white"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M102.528 37.1855C85.6516 37.1914 71.9713 50.8706 71.9644 67.7475C71.9644 81.8076 93.2054 106.381 100.266 114.206C101.487 115.549 103.599 115.549 104.82 114.206C111.881 106.383 133.092 81.8098 133.092 67.7475C133.096 50.8663 119.409 37.1808 102.528 37.1855Z"
      fill="#B0D9FF"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M86.7954 63.2333C86.7954 66.0482 89.8426 67.8075 92.2804 66.4C93.4118 65.7469 94.1087 64.5397 94.1087 63.2333C94.1087 60.4182 91.0615 58.6589 88.6237 60.0665C87.4923 60.7195 86.7954 61.9268 86.7954 63.2333Z"
      fill="#020064"
    />
    <path
      d="M111.194 63.2333C111.194 66.0482 114.242 67.8075 116.679 66.4C117.811 65.7469 118.508 64.5397 118.508 63.2333C118.508 60.4182 115.46 58.6589 113.023 60.0665C111.891 60.7195 111.194 61.9268 111.194 63.2333Z"
      fill="#020064"
    />
    <path
      d="M107.491 70.1191C107.491 73.8729 103.427 76.219 100.176 74.3422C98.6677 73.4711 97.7383 71.8613 97.7383 70.1191"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M165.4 67.0985C165.401 64.2072 163.057 61.8629 160.166 61.8642H154.666C152.138 37.1131 132.569 17.5437 107.817 15.0157V9.5158C107.737 5.48557 103.324 3.05381 99.8735 5.13853C98.3362 6.06755 97.3823 7.71982 97.3464 9.5158V15.0157C72.5953 17.5437 53.0259 37.1131 50.4978 61.8642H44.998C40.9685 61.8642 38.4502 66.2261 40.465 69.7157C41.3998 71.3352 43.1281 72.3329 44.998 72.3329H50.4978C53.0259 97.0841 72.5953 116.653 97.3464 119.181V124.681C97.4269 128.712 101.84 131.143 105.29 129.059C106.828 128.13 107.781 126.477 107.817 124.681V119.181C132.569 116.653 152.138 97.0841 154.666 72.3329H160.166C163.057 72.3342 165.401 69.9898 165.4 67.0985ZM107.742 108.624C107.488 104.652 103.028 102.443 99.7148 104.65C98.3717 105.544 97.523 107.014 97.4201 108.624C78.4138 106.236 63.4442 91.2676 61.0551 72.2614C65.0278 72.0073 67.2359 67.548 65.0295 64.2347C64.1353 62.8916 62.6655 62.0429 61.0551 61.94C63.4423 42.9321 78.4123 27.9613 97.4201 25.573C97.6741 29.5456 102.133 31.7535 105.447 29.5473C106.79 28.6531 107.638 27.1831 107.742 25.573C126.749 27.9599 141.719 42.9288 144.109 61.9357C140.136 62.1896 137.928 66.6489 140.134 69.9625C141.028 71.3055 142.498 72.1542 144.109 72.2571C141.721 91.2658 126.75 106.237 107.742 108.624Z"
      fill="white"
      stroke="#020064"
      stroke-width="0.24"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default HappyFace;
