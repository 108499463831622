import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Footer = ({ className = "" }) => {
  return (
    <footer className={`footer ${className}`}>
      <div className="footer-container">
        <div className="logo-images-wrapper">
          <div className="logo-images">
            <img
              className="image-2-icon"
              alt=""
              src="/cuppasugarlogoblack-2.svg"
            />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-info">
          <div className="legal-links">
            {/* Link to the Privacy Policy page */}
            <Link to="/privacy" className="privacy-policy">
              Privacy Policy
            </Link>

            {/* Link to the Terms and Conditions page */}
            <Link to="/terms" className="terms-and-conditions">
              Terms and Conditions
            </Link>

            {/* Link to send an email to support */}
            <a href="mailto:support@cuppasugar.io" className="contact">
              Contact
            </a>
          </div>
          <div className="copyright-container">
            <div className="copyright-icon-container-parent">
              <div className="copyright-icon-container">
                <img
                  className="copyright-svgrepocom-icon"
                  loading="lazy"
                  alt=""
                  src="/copyright-svgrepocom.svg"
                />
              </div>
              <div className="cuppasugar-all-right">
                2024 CuppaSugar (KHAMLICHI LLC). All right reserved. Empowering
                neighborhoods one favor at a time. 1501 Lincoln Blvd. #1053,
                Venice, CA 90291
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
