import Button from "./Button";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const HeaderAuth = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <button className={`header-auth ${className}`}>
      <Button
        label="Sign up"
        onClick={() => {
          navigate("/login");
        }}
        hasIconStart={false}
        hasIconEnd={false}
        propHeight="unset"
        propBorderRadius="20px"
        propBackgroundColor="#181818"
        propBorder="1px solid #2c2c2c"
        propAlignSelf="unset"
        propColor="#f5f5f5"
        propDisplay="inline-block"
        propMinWidth="59px"
        propFontWeight="bold"
      />
    </button>
  );
};

HeaderAuth.propTypes = {
  className: PropTypes.string,
};

export default HeaderAuth;
