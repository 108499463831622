import { differenceInMinutes, format, isToday, isYesterday } from "date-fns";

export const timeAgo = (timestamp, type = "ask") => {
  const now = new Date();
  const createdAt = new Date(timestamp);
  const diffInSeconds = Math.floor((now - createdAt) / 1000);

  let interval = Math.floor(diffInSeconds / 31536000);
  if (interval >= 1)
    return `${type === "response" ? "Responded" : "Asked"} ${interval} ${interval === 1 ? "year" : "years"} ago`;

  interval = Math.floor(diffInSeconds / 2592000);
  if (interval >= 1)
    return `${type === "response" ? "Responded" : "Asked"} ${interval} ${interval === 1 ? "month" : "months"} ago`;

  interval = Math.floor(diffInSeconds / 86400);
  if (interval >= 1)
    return `${type === "response" ? "Responded" : "Asked"} ${interval} ${interval === 1 ? "day" : "days"} ago`;

  interval = Math.floor(diffInSeconds / 3600);
  if (interval >= 1)
    return `${type === "response" ? "Responded" : "Asked"} ${interval} ${interval === 1 ? "hour" : "hours"} ago`;

  interval = Math.floor(diffInSeconds / 60);
  if (interval >= 1)
    return `${type === "response" ? "Responded" : "Asked"} ${interval} ${interval === 1 ? "minute" : "minutes"} ago`;

  return `${type === "response" ? "Responded" : "Asked"} just now`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const minutesDifference = differenceInMinutes(now, date);

  if (minutesDifference < 60) {
    return `${minutesDifference} mins ago`;
  } else if (isToday(date)) {
    return `Today at ${format(date, "h:mm a")}`;
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, "h:mm a")}`;
  } else if (minutesDifference <= 7 * 24 * 60) {
    return `${format(date, "EEEE")} at ${format(date, "h:mm a")}`;
  } else {
    return `${format(date, "MMM d, yyyy")} at ${format(date, "h:mm a")}`;
  }
};

export function formatUSPhoneNumber(phoneNumber) {
  // Remove all non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned number has exactly 10 digits
  if (cleaned.length !== 10) {
    return phoneNumber; // Return original input if not valid
  }

  // Format the number
  const areaCode = cleaned.slice(0, 3);
  const firstPart = cleaned.slice(3, 6);
  const secondPart = cleaned.slice(6);

  return `(${areaCode}) ${firstPart}-${secondPart}`;
}
