import React, { useEffect } from "react";
import Header from "./Header";
import AboveTheFold from "./AboveTheFold";
import CardGridIcon from "./CardGridIcon";
import CardGridIcon1 from "./CardGridIcon1";
import TextContentHeading from "./TextContentHeading";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const LandingPageDesign = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const globalStyles = document.createElement("link");
    globalStyles.rel = "stylesheet";
    globalStyles.href = "/styles/global.css";
    globalStyles.id = "global-styles";
    document.head.appendChild(globalStyles);

    const landingPageStyles = document.createElement("link");
    landingPageStyles.rel = "stylesheet";
    landingPageStyles.href = "/styles/LandingPageDesign.css";
    landingPageStyles.id = "landing-page-styles";
    document.head.appendChild(landingPageStyles);

    const otherStyles = document.createElement("link");
    otherStyles.rel = "stylesheet";
    otherStyles.href = "/styles/combined.css";
    otherStyles.id = "combined-styles";
    document.head.appendChild(otherStyles);

    return () => {
      document.head.removeChild(globalStyles);
      document.head.removeChild(landingPageStyles);
      document.head.removeChild(otherStyles);
    };
  }, []);

  return (
    <div className="landing-page-design-2">
      <Header />
      <AboveTheFold />
      <CardGridIcon1 />
      <CardGridIcon />
      <section className="page-newsletter">
        <TextContentHeading
          heading="Ready to make a difference?"
          subheading="Signing up is easy, and being part of the CuppaSugar community brings your neighborhood closer than ever. It's about more than just quick help—it's about community care, support, and camaraderie."
          hasSubheading
        />
        <div className="signup-container">
          <button
            className="signup-btn"
            onClick={() => {
              navigate("/login");
            }}
          >
            <i className="sign-up">Sign up</i>
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LandingPageDesign;
