import React from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UrgencyPill from "./UrgencyPill";
import { timeAgo } from "./utils";

const AskFeedItem = ({ item }) => {
  const navigate = useNavigate();
  const { id, attributes, includes } = item.includes.feedable;
  const { status, createdAt, timeline, photoUrl, description } = attributes;
  const { profilePhotoUrl, name, last_name } = includes.author.attributes;

  const handleClick = () => {
    navigate(`/asks/${id}`);
  };

  const truncateDescription = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
  };

  return (
    <div className="feed-item" key={`ask-${id}`} onClick={handleClick}>
      <div className="avatar-subject-container">
        <Avatar
          alt={name}
          sx={{
            width: "45px",
            height: "45px",
          }}
          src={profilePhotoUrl}
          className="feed-item-avatar"
        />
        <div className="feed-message">
          <span className="emoji">&#9995;</span>
          <strong> {`${name} ${last_name} `}</strong>
          created an Ask
        </div>

        {status === "Incomplete" ? (
          <div className="open-status">Open</div>
        ) : (
          <div className="closed-status">Closed</div>
        )}
      </div>

      {description && (
        <div className="feed-item-description">
          "{truncateDescription(description)}"
        </div>
      )}

      {photoUrl && (
        <div className="feed-item-photo-container">
          <div className="feed-item-photo-preview">
            <img src={photoUrl} alt="Ask preview" />
          </div>
        </div>
      )}

      <div className="feed-middle-container">
        <UrgencyPill urgency={timeline} />
        <div className="feed-time">{timeAgo(createdAt)}</div>
      </div>
    </div>
  );
};

export default AskFeedItem;
