import React, { useState, useRef } from "react";
import {
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { patchUser } from "../api/mainApi";
import SignUpHeader from "./SignUpHeader";

const StyledButton = styled(Button)({
  display: "flex",
  padding: "13px 25px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "12px",
  background: "#576DF3",
  color: "#FFFFFF",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "150%",
  textTransform: "none",
  marginTop: "24px",
  "&:hover": {
    background: "#3D5AFE",
  },
});

const libraries = ["places"]; // Static array outside the component

const AddressForm = () => {
  const [formData, setFormData] = useState({
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [errors, setErrors] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }

      const addressComponents = place.address_components;

      const streetNumber =
        addressComponents.find((component) =>
          component.types.includes("street_number"),
        )?.long_name || "";
      const route =
        addressComponents.find((component) => component.types.includes("route"))
          ?.long_name || "";

      const newFormData = {
        address: `${streetNumber} ${route}`.trim(),
        address2: "",
        city:
          addressComponents.find((component) =>
            component.types.includes("locality"),
          )?.long_name || "",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1"),
          )?.short_name || "",
        zip:
          addressComponents.find((component) =>
            component.types.includes("postal_code"),
          )?.long_name || "",
      };

      setFormData(newFormData);
      setIsExpanded(true);
      setErrors({});
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (!isExpanded && name === "address" && value.trim() !== "") {
      setIsExpanded(true);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.city.trim()) newErrors.city = "City is required";
    if (!formData.state.trim()) newErrors.state = "State is required";
    if (!formData.zip.trim()) newErrors.zip = "ZIP code is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const userId = localStorage.getItem("userId");
      const response = await patchUser(userId, {
        ...formData,
        name: localStorage.getItem("firstName"),
        last_name: localStorage.getItem("lastName"),
      });

      const { data } = response;
      const updatedUser = {
        id: data.id,
        ...data.attributes,
        community_id: data.includes?.community?.id,
      };

      localStorage.setItem("user", JSON.stringify(updatedUser));
      localStorage.setItem("community_id", updatedUser.community_id);

      navigate("/home");
    } catch (error) {
      console.error("Failed to update user:", error);
      setErrors({ submit: "Failed to update address. Please try again." });
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 3 }}>
      <SignUpHeader />
      <Typography variant="h5" gutterBottom align="center">
        Your Address
      </Typography>
      {!isLoaded ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteRef.current = autocomplete;
            }}
            onPlaceChanged={handlePlaceChanged}
          >
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              inputRef={inputRef}
              margin="normal"
              error={!!errors.address}
              helperText={errors.address}
            />
          </Autocomplete>
          <Collapse in={isExpanded}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="address2"
              value={formData.address2}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              margin="normal"
              error={!!errors.city}
              helperText={errors.city}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.state}
                helperText={errors.state}
              />
              <TextField
                fullWidth
                label="ZIP Code"
                name="zip"
                value={formData.zip}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.zip}
                helperText={errors.zip}
              />
            </Box>
          </Collapse>
          {errors.submit && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errors.submit}
            </Alert>
          )}
          <StyledButton type="submit" fullWidth>
            Continue
          </StyledButton>
        </form>
      )}
    </Box>
  );
};

export default AddressForm;
