import PropTypes from "prop-types";

const TextContentHeading = ({
  className = "",
  heading = "Ready to make a difference?",
  subheading = "Signing up is easy, and being part of the CuppaSugar community brings your neighborhood closer than ever. It's about more than just quick help—it's about community care, support, and camaraderie.",
  hasSubheading = true,
}) => {
  return (
    <div className={`text-content-heading1 ${className}`}>
      <h1 className="heading">{heading}</h1>
      {hasSubheading && <div className="subheading4">{subheading}</div>}
    </div>
  );
};

TextContentHeading.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  hasSubheading: PropTypes.bool,
};

export default TextContentHeading;
