const BASE_URL = process.env.REACT_APP_API_URL;
const API_AUTH_URL = `${BASE_URL}/phone/auth`;
const API_MESSAGE_URL = `${BASE_URL}/messages`;
const API_LOGIN_URL = `${BASE_URL}/phone/login`;
const API_USERS_URL = `${BASE_URL}/users`;
const API_FEED_URL = `${BASE_URL}/feed?perPage=50`;
const API_ASKS_URL = `${BASE_URL}/asks`;
const API_CATEGORIES_URL = `${BASE_URL}/categories`;

const getHeaders = (isAuth = false) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (isAuth) {
    const token = localStorage.getItem("token");
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const handleResponse = async (response) => {
  if (!response.ok) {
    if (response.status === 401) {
      // Check if the status is 401 (Unauthorized)
      localStorage.clear(); // Clear local storage
      window.location.href = "/login"; // Redirect to login page
      return;
    }
    const errorData = await response.json();
    throw new Error(
      errorData.message || `API request failed with status: ${response.status}`,
    );
  }
  return response.json();
};

export const sendPhoneNumber = async (phoneNumber) => {
  try {
    const response = await fetch(
      `${API_AUTH_URL}?phone=${encodeURIComponent(phoneNumber)}`,
      {
        method: "POST",
        headers: getHeaders(),
      },
    );
    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchMessages = async () => {
  try {
    const response = await fetch(API_MESSAGE_URL, {
      headers: getHeaders(),
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const loginUser = async (phone, code) => {
  try {
    const inviteCode = localStorage.getItem("invite");

    const requestBody = {
      phone: phone,
      code: code,
    };

    if (inviteCode) {
      requestBody.invite_code = inviteCode;
    }

    const response = await fetch(`${API_LOGIN_URL}?include=community`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...getHeaders(),
      },
      body: JSON.stringify(requestBody),
    });

    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchTotalNeighbors = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/communities/${id}`, {
      method: "GET",
      headers: getHeaders(true),
    });

    const data = await handleResponse(response);
    return data.data.attributes.users_count;
  } catch (error) {
    console.error("Error fetching total neighbors:", error);
    throw error;
  }
};

export const patchUser = async (userId, data) => {
  try {
    const response = await fetch(
      `${API_USERS_URL}/${userId}?_method=PATCH&include=community`,
      {
        method: "POST",
        headers: getHeaders(true),
        body: JSON.stringify({ data: { attributes: { ...data } } }),
      },
    );
    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchFeed = async (type = "all", userId = null) => {
  try {
    let url = API_FEED_URL;
    if (type === "open") {
      url += "&asks=open";
    } else if (type === "user" && userId) {
      url += `&filter[user]=${userId}`;
    }

    const response = await fetch(url, {
      headers: getHeaders(true),
    });
    const data = await handleResponse(response);
    return data.data;
  } catch (error) {
    console.error("Error fetching feed:", error);
    throw error;
  }
};

export const createAsk = async (formData) => {
  try {
    const response = await fetch(API_ASKS_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchCategories = async () => {
  try {
    const response = await fetch(API_CATEGORIES_URL, {
      headers: getHeaders(true),
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchSingleAsk = async (id) => {
  try {
    const response = await fetch(
      `${API_ASKS_URL}/${id}?include=author,category,responses,responses.author`,
      {
        headers: getHeaders(true),
      },
    );
    return await handleResponse(response);
  } catch (error) {
    console.error("Error fetching ask details:", error);
    throw error;
  }
};

export const fetchUserInfo = async (userId) => {
  try {
    const response = await fetch(`${API_USERS_URL}/${userId}`, {
      headers: getHeaders(true),
    });
    return await handleResponse(response);
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

export const sendResponse = async (askId, message) => {
  try {
    const response = await fetch(`${API_ASKS_URL}/${askId}/responses`, {
      method: "POST",
      headers: getHeaders(true),
      body: JSON.stringify({
        data: {
          attributes: {
            message,
            status: "Accepted",
          },
        },
      }),
    });

    return await handleResponse(response);
  } catch (error) {
    console.error("Error sending response:", error);
    throw error;
  }
};

export const fetchUserAsks = async (userId) => {
  try {
    const response = await fetch(
      `${API_USERS_URL}/${userId}/asks?include=author,category,responses&sort=description,-createdAt`,
      {
        headers: getHeaders(true),
      },
    );
    const data = await handleResponse(response);
    return data.data;
  } catch (error) {
    console.error("Error fetching user asks:", error);
    throw error;
  }
};
