import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { ReactComponent as AcceptImage } from "../images/accept-ask-image.svg";
import { formatUSPhoneNumber } from "./utils";
import SendSMSButton from "./buttons/SendSMSButton";

const ResponseConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, lastName, address, phone } = location.state || {}; // Get the passed data

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        padding: "24px",
      }}
    >
      {/* Placeholder for image */}
      <AcceptImage />

      <div className={"response-congrats-text"}>
        Thank you &#128079; You have offered your neighbor a helping hand!
      </div>
      <div className={"response-congrats-text-1"}>
        {`${name} `} has been notified about your response and will reach out
        soon. However if you want to reach out first, here are {`${name}`}'s
        details:
      </div>
      <h2 className={"response-congrats-name"}>{`${name} ${lastName}`}</h2>
      <p>{address}</p>
      <p>{formatUSPhoneNumber(phone)}</p>
      <SendSMSButton phoneNumber={phone}></SendSMSButton>
      <Button
        variant="contained"
        sx={{
          marginTop: "20px",
          backgroundColor: "#576DF3",
          color: "#FFF",
          padding: "10px 20px",
          borderRadius: "8px",
          textTransform: "none",
        }}
        onClick={() => navigate("/home")} // Navigate back to the homepage
      >
        Return to homepage
      </Button>
    </Box>
  );
};

export default ResponseConfirmation;
