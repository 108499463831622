import React from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PageTitle = ({ title, showBackButton = true, backRoute = null }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (backRoute) {
      navigate(backRoute);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 3,
        py: 0,
      }}
    >
      {showBackButton && (
        <IconButton
          onClick={handleBackClick}
          sx={{
            mr: 2,
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <ArrowBack />
        </IconButton>
      )}
      <Typography
        variant="h6"
        component="h1"
        sx={{
          fontSize: "1.2rem",
          fontWeight: 600,
          color: theme.palette.text.primary,
          flexGrow: 1,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
