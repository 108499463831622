import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useInviteCode = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const inviteCode = searchParams.get("invite");

    if (inviteCode) {
      localStorage.setItem("invite", inviteCode);
    }
  }, [location]);
};

export default useInviteCode;
