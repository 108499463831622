import React, { useState } from "react";
import {
  TextField,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import { createAsk } from "../api/mainApi";
import ConfirmationModal from "./ConfirmationModal";
import { ReactComponent as ImageHolder } from "../images/image.svg";
import { ReactComponent as BackButton } from "../images/back-button.svg";
import { styled } from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import {
  AccessAlarm,
  CalendarToday,
  CheckCircle,
  NavigateNext,
  Today,
} from "@mui/icons-material";
import PageTitle from "./PageTitle";

const Ask = () => {
  const [urgency, setUrgency] = useState("Today");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/home");
  };

  const handleSubmit = () => {
    setOpenModal(true);
  };

  const handleConfirm = async () => {
    // Create a FormData object to handle the multipart/form-data request
    const formData = new FormData();
    formData.append("data[attributes][description]", description);
    formData.append("data[attributes][timeline]", urgency);

    // If an image is provided, append it to the form data
    if (image) {
      formData.append("data[file][image]", image);
    }

    try {
      await createAsk(formData);
      setOpenModal(false);
      navigate("/ask/confirmation");
    } catch (error) {
      console.error("Error submitting ask:", error);
    }
  };

  const handleTimeChange = (time) => {
    setUrgency(time);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImageName(e.target.files[0].name);
    }
  };

  const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    borderRadius: "12px",
    border: "1px solid #E0E0E0",
    overflow: "hidden",
    width: "100%",
  }));

  const StyledButton = styled(Button)(({ theme, selected }) => ({
    flex: 1,
    borderRadius: "0px",
    borderColor: selected ? "#E0E0E0" : "#FFFFFF",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    backgroundColor: selected ? "#E0E0E0" : "#FFFFFF",
    color: selected ? "#000000" : "#9E9E9E",
    "&:hover": {
      backgroundColor: selected ? "#E0E0E0" : "#F5F5F5",
    },
  }));

  const ImageUploadButton = styled(Button)(({ theme }) => ({
    borderRadius: "8px",
    border: "1px solid #E8ECEF",
    display: "flex",
    height: "48px",
    padding: "24px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    fontFamily: "Inter, sans-serif",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }));

  return (
    <div className={"ask-container"}>
      <DashboardHeader />
      <PageTitle title="Create Ask" backRoute="/home" />
      <div className="ask-form-container">
        <Button
          variant="outlined"
          endIcon={<InfoIcon />}
          onClick={handleDialogOpen}
          sx={{
            display: "flex",
            padding: "16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "2px solid #576DF3",
            background: "rgba(189, 207, 255, 0.39)",
            color: "#576DF3",
            textAlign: "center",
            fontFamily: "Roboto, sans-serif",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            textTransform: "none",
          }}
        >
          What can I ask for?
        </Button>

        <div>How urgent is this?</div>
        <StyledButtonGroup>
          <StyledButton
            selected={urgency === "Now"}
            onClick={() => handleTimeChange("Now")}
            startIcon={<AccessAlarm />}
          >
            Now
          </StyledButton>
          <StyledButton
            selected={urgency === "Today"}
            onClick={() => handleTimeChange("Today")}
            startIcon={<Today />}
          >
            Today
          </StyledButton>
          <StyledButton
            selected={urgency === "Soon"}
            onClick={() => handleTimeChange("Soon")}
            startIcon={<CalendarToday />}
          >
            Soon
          </StyledButton>
        </StyledButtonGroup>

        <TextField
          className="input description-input"
          label="Enter Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{ maxLength: 500 }}
          helperText={`${description.length}/500 Character limit`}
          InputProps={{
            style: {
              borderRadius: "8px",
              borderColor: "#C1C0C0",
              background: "#FFF",
            },
          }}
        />

        <ImageUploadButton
          variant="outlined"
          component="label"
          className="upload-button"
          fullWidth
          sx={{
            color: image ? "#4CAF50" : "#848484",
            backgroundColor: image ? "#E8F5E9" : "transparent",
            "&:hover": {
              backgroundColor: image ? "#C8E6C9" : "#F5F5F5",
            },
          }}
        >
          {image ? (
            <>
              <CheckCircle style={{ color: "#4CAF50" }} />
              {`Image uploaded: ${imageName}`}
            </>
          ) : (
            <>
              <ImageHolder />
              Upload an image
            </>
          )}
          <input
            type="file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </ImageUploadButton>

        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          fullWidth
          onClick={handleSubmit}
          disabled={!urgency || !description}
          startIcon={<NavigateNext />}
          sx={{
            color: "#FFF",
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "150%",
            display: "flex",
            padding: "13px 25px",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "12px",
            background: "#576DF3",
            textTransform: "none",
          }}
        >
          Next
        </Button>
        <Button
          variant="contained"
          className="submit-button"
          fullWidth
          onClick={handleBack}
          sx={{
            color: "#BABABA",
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "150%",
            display: "flex",
            padding: "13px 25px",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "12px",
            boxShadow: "none",
            background: "white",
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{"What Can You Ask For?"}</DialogTitle>
        <DialogContent>
          <p>
            CuppaSugar is all about neighbors helping neighbors with small tasks
            and items. Here’s what you can request:
          </p>
          <ul>
            <li>
              <b>Tools & Equipment:</b> Need a tool for a quick fix or project?
              Borrow anything from drills to ladders.
            </li>
            <li>
              <b>Gardening & Lawn Care:</b> Keep your garden or lawn in shape
              with borrowed mowers, trimmers, and more.
            </li>
            <li>
              <b>Cleaning & Maintenance:</b> Tackle household chores with
              borrowed carpet cleaners, pressure washers, and other cleaning
              tools.
            </li>
            <li>
              <b>Home Improvement:</b> Working on a DIY project? Ask for tools
              like sanders, paint sprayers, or whatever else you need to get it
              done.
            </li>
            <li>
              <b>Automotive & Mechanical:</b> Fix up your vehicle or make
              repairs with items like socket sets or wrenches.
            </li>
            <li>
              <b>Cooking & Kitchen:</b> Need a specific kitchen gadget? Ask for
              specialty bakeware, slow cookers, or anything else for your
              culinary needs.
            </li>
            <li>
              <b>Hobbies & Crafts:</b> Pursue your hobbies with borrowed
              crafting tools, sports gear, or instruments.
            </li>
            <li>
              <b>Personal & Household Services:</b> Ask for help with small
              favors—like feeding your pet, house sitting, or moving a piece of
              furniture.
            </li>
            <li>
              <b>Outdoor & Seasonal:</b> Prepare for the season with borrowed
              camping gear, snow shovels, or patio heaters.
            </li>
            <li>
              <b>Miscellaneous:</b> Have a unique request that doesn’t fit into
              a category? Ask away—your neighbors are here to help.
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationModal
        open={openModal}
        image={image}
        onClose={() => setOpenModal(false)}
        onConfirm={handleConfirm}
        description={description}
        urgency={urgency}
      />
    </div>
  );
};

export default Ask;
