import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { ReactComponent as CloseButton } from "../images/close-button.svg";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const OfferHelpDialog = ({ open, onClose, onConfirm }) => {
  const [note, setNote] = useState("");
  const [error, setError] = useState("");

  const handleNoteChange = (e) => {
    setNote(e.target.value);
    if (e.target.value.trim()) {
      setError("");
    }
  };

  const handleConfirm = () => {
    if (note.trim()) {
      onConfirm(note);
      setNote("");
      setError("");
    } else {
      setError("Please enter a message.");
    }
  };

  const handleClose = () => {
    setNote("");
    setError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          padding: "16px",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div className={"dialog-close-button"}>
          <CloseButton onClick={handleClose} />
        </div>
        <Typography>
          Please enter a message to send with your response
        </Typography>
        <DialogContent
          sx={{
            padding: "0px",
            width: "100%",
          }}
        >
          <TextField
            autoFocus
            label="Enter Message"
            fullWidth
            variant="outlined"
            value={note}
            onChange={handleNoteChange}
            multiline
            minRows={2}
            maxRows={6}
            required
            error={!!error}
            helperText={error}
            InputProps={{
              inputComponent: TextareaAutosize,
            }}
            sx={{
              borderRadius: "10px",
              borderColor: "#C1C0C0",
              background: "#FFF",
              color: "#959595",
              fontFamily: "Roboto, sans-serif",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              "& .MuiInputBase-inputMultiline": {
                resize: "none",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            sx={{
              display: "flex",
              padding: "13px 25px",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "#576DF3",
              color: "white",
              textTransform: "none",
              "&:hover": {
                background: "#3D5AFE",
              },
            }}
            startIcon={<Check />}
          >
            Confirm
          </Button>
        </DialogActions>
        <Typography className={"dialog-disclaimer"}>
          Once you press confirm your address and number will be shared with
          this neighbor. They will reach out to coordinate.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default OfferHelpDialog;
