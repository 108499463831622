import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

const BUTTON_HEIGHT = "60px";

const NewAskButton = styled(Button)({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#576DF3",
  color: "#FFFFFF",
  width: "100%",
  height: BUTTON_HEIGHT,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 0,
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "none",
  zIndex: 1000,
  touchAction: "manipulation", // Add this line
  "&:hover": {
    backgroundColor: "#3D5AFE",
  },
});

const Spacer = styled("div")({
  height: BUTTON_HEIGHT,
});

const NewAskButtonComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ask/create");
  };

  return (
    <>
      <Spacer />
      <NewAskButton onClick={handleClick} startIcon={<AddIcon />}>
        Create New Ask
      </NewAskButton>
    </>
  );
};

export default NewAskButtonComponent;
