import React from "react";
import { Route, Routes } from "react-router-dom";
import PersonalInfoForm from "./PersonalInfoForm";
import AddressForm from "./AddressForm";

const SignUp = () => {
  return (
    <div>
      <Routes>
        <Route path="/name" element={<PersonalInfoForm />} />
        <Route path="/address" element={<AddressForm />} />
      </Routes>
    </div>
  );
};

export default SignUp;
