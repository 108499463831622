import React from "react";
import { AccessAlarm, Today, CalendarToday } from "@mui/icons-material";

const UrgencyPill = ({ urgency }) => {
  const getUrgencyInfo = () => {
    switch (urgency.toLowerCase()) {
      case "now":
        return {
          class: "timeline-now",
          icon: <AccessAlarm fontSize="small" />,
        };
      case "today":
        return { class: "timeline-today", icon: <Today fontSize="small" /> };
      case "soon":
        return {
          class: "timeline-soon",
          icon: <CalendarToday fontSize="small" />,
        };
      default:
        return { class: "", icon: null };
    }
  };

  const { class: urgencyClass, icon } = getUrgencyInfo();

  return (
    <div className={`timeline-text ${urgencyClass}`}>
      {icon}
      <span>{urgency}</span>
    </div>
  );
};

export default UrgencyPill;
