import React from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { timeAgo } from "./utils";

const ResponseFeedItem = ({ item }) => {
  const navigate = useNavigate();
  const { id, attributes, includes } = item.includes.feedable;
  const { createdAt } = attributes;
  const { profilePhotoUrl, name, last_name } = includes.author.attributes;
  const category =
    item.includes.feedable.includes.ask.includes.category.attributes.name;
  const askId = item.includes.feedable.includes.ask.id;
  const askAuthor = includes.ask.includes.author;
  const message = item.includes.feedable.attributes.message;

  const handleClick = () => {
    navigate(`/asks/${askId}`);
  };

  const truncateDescription = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
  };

  return (
    <div className="feed-item" key={`response-${id}`} onClick={handleClick}>
      <div className="avatar-subject-container">
        <Avatar
          alt={name}
          sx={{
            width: "45px",
            height: "45px",
          }}
          src={profilePhotoUrl}
          className="feed-item-avatar"
        />
        <div className="feed-message">
          <span className="emoji">&#127881;</span>
          <strong> {`${name} ${last_name} `}</strong>
          responded to{" "}
          <strong>
            {`${askAuthor.attributes.name} ${askAuthor.attributes.last_name}`}
            's
          </strong>{" "}
          Ask
        </div>
      </div>
      {/*{message && (*/}
      {/*  <div className="feed-item-description">*/}
      {/*    "{truncateDescription(message)}"*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="feed-middle-container">
        <div className="feed-time">{timeAgo(createdAt, "response")}</div>
      </div>
    </div>
  );
};

export default ResponseFeedItem;
