import React from "react";
import { Box, Typography } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const Quote = ({
  text,
  backgroundColor = "#f5f5f5",
  borderColor = "#1A1F36",
  textColor = "#1A1F36",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: backgroundColor,
        borderLeft: `4px solid ${borderColor}`,
        borderRadius: "4px",
        padding: "16px",
        margin: "10px 0",
      }}
    >
      <FormatQuoteIcon
        sx={{
          fontSize: 40,
          color: borderColor,
          marginRight: "12px",
        }}
      />
      <Typography
        sx={{
          opacity: "var(--sds-size-stroke-border)",
          color: textColor,
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Quote;
