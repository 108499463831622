import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  CircularProgress,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as EmptyStateSVG } from "../images/dashboard-image.svg";
import { fetchTotalNeighbors, fetchFeed } from "../api/mainApi";
import NewAskButton from "./NewAskButton";
import DashboardHeader from "./DashboardHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Share, People } from "@mui/icons-material";
import AskFeedItem from "./AskFeedItem";
import ResponseFeedItem from "./ResponseFeedItem";

const CustomTab = styled(Tab)({
  borderRadius: "48px",
  minWidth: "40px",
  minHeight: "40px",
  margin: "0 5px",
  fontFamily: "Montserrat, sans-serif",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: "#2196F3",
    color: "#fff",
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "#FFFFFF",
    color: "#BFBFBF",
  },
});

const ConnectedPaper = styled(Paper)({
  display: "flex",
  alignItems: "stretch",
  borderRadius: "8px",
  marginBottom: "20px",
  overflow: "hidden",
  width: "100%",
  height: "60px",
  boxShadow: "none",
  border: "1px solid #E0E0E0",
});

const NeighborCountSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 16px",
  width: "50%",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
});

const InviteButton = styled(Button)({
  width: "50%",
  color: "#2196F3",
  fontFamily: "Inter, sans-serif",
  fontSize: "13px",
  fontWeight: 500,
  textTransform: "none",
  borderLeft: "1px solid #E0E0E0",
  borderRadius: 0,
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
});

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const asksParam = searchParams.get("asks");
  const initialTab = asksParam === "open" ? 1 : asksParam === "user" ? 2 : 0;

  const [totalNeighbors, setTotalNeighbors] = useState(0);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      let communityId = user?.community_id;

      if (!communityId) {
        communityId = localStorage.getItem("communityId");
      }

      try {
        if (communityId && communityId !== "undefined") {
          const total = await fetchTotalNeighbors(communityId);
          setTotalNeighbors(total);
        }

        let feedType = "all";
        if (selectedTab === 1) {
          feedType = "open";
        } else if (selectedTab === 2) {
          feedType = "user";
        }

        const feedData = await fetchFeed(feedType, user.id);
        setFeed(feedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedTab, user?.community_id, user?.id]);

  const inviteCode = user?.inviteCode;

  const handleInviteClick = () => {
    if (inviteCode) {
      const inviteMessage = `Hey! Let's make our neighborhood even more connected! CuppaSugar lets us borrow, lend, and help out with whatever we need. I'm on it—join me and let's get started! https://cuppasugar.io?invite=${inviteCode}`;
      window.location.href = `sms:?&body=${encodeURIComponent(inviteMessage)}`;
    } else {
      console.error("Invite code not found");
    }
  };

  const handleCommunityClick = () => {
    navigate("/community");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setFeed([]);
    setLoading(true);
  };

  const renderFeedItem = (item) => {
    const { type } = item.includes.feedable;

    if (type === "ask") {
      return (
        <AskFeedItem key={`ask-${item.includes.feedable.id}`} item={item} />
      );
    } else if (type === "response") {
      return (
        <ResponseFeedItem
          key={`response-${item.includes.feedable.id}`}
          item={item}
        />
      );
    }
    return null;
  };

  return (
    <div className="dashboard-container">
      <div className="feed-dashboard-content">
        <DashboardHeader />
        <ConnectedPaper>
          <NeighborCountSection onClick={handleCommunityClick}>
            <People sx={{ mr: 1, color: "primary.main" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  lineHeight: 1,
                }}
              >
                {totalNeighbors}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Total Neighbors
              </Typography>
            </Box>
          </NeighborCountSection>
          <InviteButton onClick={handleInviteClick} startIcon={<Share />}>
            Invite More
          </InviteButton>
        </ConnectedPaper>
        <div className="custom-tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dashboard Tabs"
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <CustomTab label="All Activity" />
            <CustomTab label="Open Activity" />
            <CustomTab label="My Activity" />
          </Tabs>
        </div>
      </div>
      <div className="feed-container">
        {loading ? (
          <CircularProgress />
        ) : feed.length === 0 ? (
          <div className="empty-state">
            <EmptyStateSVG />
            <div className="svg-text">
              No one has asked for anything in your community yet, be the first
              to get help!
            </div>
            <Button
              variant="contained"
              className="create-ask-button"
              onClick={() => navigate("/ask/create")}
              style={{
                borderRadius: "8px",
                background: "#F5610B",
                display: "flex",
                width: "263px",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "none",
              }}
            >
              Create an Ask
            </Button>
          </div>
        ) : (
          feed.map(renderFeedItem)
        )}
      </div>
      <NewAskButton className="new-ask-button" />
    </div>
  );
};

export default Dashboard;
