import { useMemo } from "react";
import Button from "./Button";
import PropTypes from "prop-types";

const Card1 = ({
  className = "",
  link2,
  heading = "Build Connections",
  body = "As you engage with the platform, build lasting relationships with your neighbors, enhancing community trust and support.",
  star,
  x,
  asset = true,
  button = false,
  propFlex,
  propWidth,
  star1,
  label,
  x1,
  hasIconStart,
  hasIconEnd,
  propHeight,
  propBorderRadius,
  propBackgroundColor,
  propBorder,
  propAlignSelf,
  propColor,
  propDisplay,
  propMinWidth,
  propFontWeight,
}) => {
  const cardStyle = useMemo(() => {
    return {
      flex: propFlex,
      width: propWidth,
    };
  }, [propFlex, propWidth]);

  return (
    <div className={`card ${className}`} style={cardStyle}>
      {asset && (
        <img className="link-2-icon" loading="lazy" alt="" src={link2} />
      )}
      <div className="body">
        <div className="text">
          <h3 className="title">{heading}</h3>
          <div className="body-text-for">{body}</div>
        </div>
        {button && (
          <div className="button-group">
            <div className="button4">
              <img className="star-icon2" alt="" src={star} />
              <div className="button5">Button</div>
              <img className="x-icon2" alt="" src={x} />
            </div>
            <Button
              star={star1}
              label={label}
              x={x1}
              hasIconStart={hasIconStart}
              hasIconEnd={hasIconEnd}
              propHeight={propHeight}
              propBorderRadius={propBorderRadius}
              propBackgroundColor={propBackgroundColor}
              propBorder={propBorder}
              propAlignSelf={propAlignSelf}
              propColor={propColor}
              propDisplay={propDisplay}
              propMinWidth={propMinWidth}
              propFontWeight={propFontWeight}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Card1.propTypes = {
  className: PropTypes.string,
  link2: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  star: PropTypes.string,
  x: PropTypes.string,
  asset: PropTypes.bool,
  button: PropTypes.bool,
  star1: PropTypes.string,
  label: PropTypes.string,
  x1: PropTypes.string,
  hasIconStart: PropTypes.bool,
  hasIconEnd: PropTypes.bool,
  propHeight: PropTypes.string,
  propBorderRadius: PropTypes.string,
  propBackgroundColor: PropTypes.string,
  propBorder: PropTypes.string,
  propAlignSelf: PropTypes.string,
  propColor: PropTypes.string,
  propDisplay: PropTypes.string,
  propMinWidth: PropTypes.string,
  propFontWeight: PropTypes.string,

  /** Style props */
  propFlex: PropTypes.any,
  propWidth: PropTypes.any,
};

export default Card1;
