import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  CircularProgress,
  Box,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Sms, ArrowBack, Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import { useGlobal } from "../contexts/GlobalContext";
import PageTitle from "./PageTitle";

const Community = () => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { globalState } = useGlobal();
  const [showNotice, setShowNotice] = useState(() => {
    return localStorage.getItem("communityPrivacyNoticeDismissed") !== "true";
  });

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const communityId = localStorage.getItem("communityId");
        if (!communityId) {
          console.error("Community ID not found in localStorage");
          setLoading(false);
          return;
        }
        const response = await fetch(
          `${globalState.apiUrl}/users?filter[community]=${communityId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        const data = await response.json();
        setMembers(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching community members:", error);
        setLoading(false);
      }
    };
    fetchMembers();
  }, [globalState.apiUrl]);

  const handleBackClick = () => {
    navigate("/home");
  };

  const handleDismissNotice = () => {
    setShowNotice(false);
    localStorage.setItem("communityPrivacyNoticeDismissed", "true");
  };

  const handleSMSClick = (phoneNumber) => {
    window.location.href = `sms:${phoneNumber}`;
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 2 }}>
        <DashboardHeader />
        <PageTitle title="My Community" backRoute="/home" />
        {showNotice && (
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleDismissNotice}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 3 }}
          >
            Full details and messaging are only available for neighbors you've
            connected with through asks. The same privacy rules apply to your
            information.
          </Alert>
        )}
        <Grid container spacing={3}>
          {members.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              <Card
                elevation={3}
                sx={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Avatar
                      alt={`${member.attributes.name} ${member.attributes.lastName}`}
                      src={member.attributes.profilePhotoUrl}
                      sx={{ width: 40, height: 40, mr: 1.5 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "1rem", fontWeight: "medium" }}
                    >
                      {`${member.attributes.name} ${member.attributes.lastName}`}
                    </Typography>
                  </Box>
                  {(member.attributes.address ||
                    member.attributes.address2) && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      <Home
                        fontSize="small"
                        sx={{ mr: 1, verticalAlign: "middle" }}
                      />
                      {member.attributes.address}
                      {member.attributes.address2 &&
                        `, ${member.attributes.address2}`}
                    </Typography>
                  )}
                </CardContent>
                {member.attributes.phone && (
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      fullWidth
                      onClick={() => handleSMSClick(member.attributes.phone)}
                      sx={{
                        backgroundColor: "#FF6F00",
                        color: "#FFF",
                        borderRadius: "8px",
                        padding: "8px 16px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#E65100",
                        },
                      }}
                      startIcon={<Sms />}
                    >
                      Message
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Community;
