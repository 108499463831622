import Button from "./Button";
import HeaderAuth from "./HeaderAuth";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Header = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <header className={`header ${className}`}>
      <img
        className="cuppasugar-logo-black-2-icon"
        loading="lazy"
        alt=""
        src="/cuppasugarlogoblack-2.svg"
      />
      <div className="auth-buttons">
        <div className="header-auth1">
          <div className="button2">
            <div className="button3">Sign in</div>
          </div>
          <Button
            star="/star.svg"
            label="Log in"
            x="/x.svg"
            onClick={() => {
              navigate("/login");
            }}
            hasIconStart={false}
            hasIconEnd={false}
            propHeight="unset"
            propBorderRadius="20px"
            propBackgroundColor="unset"
            propBorder="unset"
            propAlignSelf="unset"
            propColor="#000"
            propDisplay="inline-block"
            propMinWidth="48px"
            propFontWeight="bold"
          />
        </div>
        <HeaderAuth />
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
